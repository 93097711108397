import { Outlet } from "react-router-dom";

import { FooterLinks } from "@/pages/Home";

import NavBar from "../navigation/PublicNavBar";
import { FooterCentered } from "./Footer";

function PublicLayout() {
  return (
    <div>
      <NavBar />
      <Outlet />
      <FooterCentered links={FooterLinks} />
    </div>
  );
}

export default PublicLayout;
