import { atom, selector } from "recoil";

export const authState = atom({
  key: "authState",
  default: !!localStorage.getItem("token"),
});

export const ModalState = atom({
  key: "ModalState",
  default: false,
});

export const authStateSelector = selector({
  key: "authStateSelector",
  get: ({ get }) => {
    return get(authState);
  },
  set: ({ set }, newValue) => {
    set(authState, newValue);
  },
});

export const userState = atom({
  key: "userState",
  default: JSON.parse(localStorage.getItem("user") || "{}"),
});

export const transactionMemberState = atom({
  key: "transactionMemberState",
  default: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
});
