import "@/App.css";

import { DEFAULT_THEME, LoadingOverlay } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark } from "@tabler/icons-react";
import jwt_decode from "jwt-decode";
import { lazy, Suspense, useEffect, useRef } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { NothingFoundBackground } from "@/components/navigation/NothingFoundBackground";
import Home from "@/pages/Home";
import Login from "@/pages/Login";
import Standings from "@/pages/Standings";
import { authStateSelector, userState } from "@/store/Atoms";

import Competitions from "./components/LandingPage/Competitions/Competitions";
import OpenAndJunior from "./components/LandingPage/Competitions/OpenAndJunior";
import Tournaments from "./components/LandingPage/Competitions/Tournaments";
import Gallery from "./components/LandingPage/Gallery";
import PublicLayout from "./components/LandingPage/Layout";
import ProtectedRoute from "./components/navigation/ProtectedRoute";
import Inquries from "./pages/Inquire";
import SignUp from "./pages/SignUp";

interface IDecodedToken {
  exp: number;
  iat: number;
  id: number;
  iss: string;
  role: string;
  status: string;
}

const Members = lazy(() => import("@/pages/protected/members/Members"));
const CreateMember = lazy(
  () => import("@/pages/protected/members/CreateMember")
);
const SingleMember = lazy(
  () => import("@/pages/protected/members/SingleMember")
);
const Memberships = lazy(
  () => import("@/pages/protected/membership/memberships")
);
const CreateMemberships = lazy(
  () => import("@/pages/protected/membership/CreateMembership")
);
const UpdateMemberships = lazy(
  () => import("@/pages/protected/membership/UpdateMembership")
);
const Events = lazy(() => import("./pages/protected/events/Events"));
const UpdateEvent = lazy(
  () => import("@/pages/protected/events/UpdateSingleEvent")
);
const CreateEvent = lazy(() => import("@/pages/protected/events/CreateEvent"));
const UpcomingEvents = lazy(
  () => import("@/pages/protected/events/UpcomingEvents")
);
const EventSinglePayment = lazy(
  () => import("@/pages/protected/events/EventSinglePayment")
);
const CreateEventType = lazy(
  () => import("@/pages/protected/events/CreateEventType")
);
const Transaction = lazy(
  () => import("@/pages/protected/transactions/EditSingleTransaction")
);
const UserProfilePage = lazy(
  () => import("@/pages/protected/users/UserProfilePage")
);
const AllUsersPage = lazy(() => import("@/pages/protected/users/AllUsersPage"));
const SingleUserEditPage = lazy(
  () => import("@/pages/protected/users/SingleUserEditPage")
);
const AddUserPage = lazy(() => import("@/pages/protected/users/AddUserPage"));
const AllTeamsPage = lazy(() => import("@/pages/protected/Teams/AllTeamsPage"));
const AllTeamMemberPage = lazy(
  () => import("@/pages/protected/Teams/TeamMember/AllTeamMemberPage")
);
const EditSingleTeam = lazy(
  () => import("@/pages/protected/Teams/EditSingleTeam")
);
const EditSingleTeamMemberPage = lazy(
  () => import("@/pages/protected/Teams/TeamMember/EditSingleTeamMemberPage")
);
const AllMatchesPage = lazy(
  () => import("@/pages/protected/Matches/AllMatchesPage")
);
const SingleMatchesTabPage = lazy(
  () => import("@/pages/protected/Matches/SingleMatchesTabPage")
);
const CreateMatchScorePage = lazy(
  () => import("@/pages/protected/Matches/CreateMatchScorePage")
);
const AllEventCategoryPage = lazy(
  () => import("@/pages/protected/events/AllEventCategoryPage")
);
const UpdateEventCategoryPage = lazy(
  () => import("@/pages/protected/events/UpdateEventCategoryPage")
);
const AnnouncementsPage = lazy(
  () => import("@/pages/protected/announcements/AnnouncementsPage")
);
const MemberEventsPage = lazy(
  () => import("@/pages/protected/LoggedInMember/events/MemberEventsPage")
);
const AdminDashboardPage = lazy(
  () => import("@/pages/protected/dashboard/AdminDashboardPage")
);
const SingleInquiryPage = lazy(
  () => import("@/pages/protected/Inquiry/SingleInquiryPage")
);

function App() {
  const user = useRecoilValue(userState);
  const isAuthenticated = useRecoilValue(authStateSelector);
  const setAuthenticated = useSetRecoilState(authStateSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const customLoader = (
    <svg
      width="54"
      height="54"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      stroke={DEFAULT_THEME.colors.blue[6]}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );

  useEffect(() => {
    const logoutWhenTokenExpires = () => {
      const u = localStorage.getItem("token");

      if (u) {
        const decodedToken: IDecodedToken = jwt_decode(u);
        const currentTime = Date.now() / 1000;
        const timeUntilExpiration = (decodedToken.exp - currentTime) * 1000;

        if (timeUntilExpiration > 0) {
          timerId.current = setTimeout(() => {
            showNotification({
              title: "Error",
              message: "Your session has expired. Please log in again.",
              color: "red",
              icon: <IconExclamationMark />,
              autoClose: false,
            });
            setAuthenticated(false);
            localStorage.clear();
            navigate("/auth/login");
          }, timeUntilExpiration);
        } else {
          // Token is already expired, log the user out
          setAuthenticated(false);
          localStorage.clear();
          navigate("/auth/login");
        }
      }
    };

    logoutWhenTokenExpires();

    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, [location]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isPublicRoute = (path: string) => {
      const publicRoutesRegex = [
        /^\/$/,
        /^\/auth\/login$/,
        /^\/standings$/,
        /^\/home$/,
        /^\/404$/,
        /^\/gallery$/,
        /^\/about$/,
        /^\/contact$/,
        /^\/competitions$/,
        /^\/contact-us$/,
        /^\/register$/,
        /^\/filled$/,
        /^\/competitions\/open$/,
        /^\/competitions\/tournaments$/,
      ];

      return publicRoutesRegex.some((regex) => regex.test(path));
    };

    const isPrivateRoute = (path: string) => {
      const privateRoutesRegex = [
        /^\/dashboard$/,
        /^\/members$/,
        /^\/members\/create$/,
        /^\/members\/update\/\d+$/,
        /^\/memberships$/,
        /^\/memberships\/create$/,
        /^\/memberships\/update\/\d+$/,
        /^\/events$/,
        /^\/events\/update\/\d+$/,
        /^\/events\/create$/,
        /^\/events\/create-category$/,
        /^\/transactions\/\d+$/,
        /^\/users$/,
        /^\/users\/update\/\d+$/,
        /^\/users\/create$/,
        /^\/teams$/,
        /^\/teams\/update\/\d+$/,
        /^\/teams\/members$/,
        /^\/teams\/members\/update\/\d+$/,
        /^\/matches$/,
        /^\/matches\/match\/\d+$/,
        /^\/matches\/create\/result$/,
        /^\/matches\/create\/score$/,
        /^\/events\/categories$/,
        /^\/events\/categories\/update\/\d+$/,
        /^\/events\/upcoming$/,
        /^\/events\/upcoming\/\d+$/,
        /^\/user\/profile\/\d+$/,
        /^\/announcements$/,
        /^\/dashboard\/inquiry\/\d+$/,

        // Logged in member routes
        /^\/member\/events$/,
      ];

      return privateRoutesRegex.some((regex) => regex.test(path));
    };

    if (isPublicRoute(location.pathname)) {
      // Public route, do nothing
    } else if (isPrivateRoute(location.pathname)) {
      if (!token) {
        showNotification({
          title: "Error",
          message: "You are not authorized to view this page.",
          color: "red",
          icon: <IconExclamationMark />,
          autoClose: true,
        });
        navigate("/auth/login");
        setAuthenticated(false);
        localStorage.clear();
      }
    } else {
      // If the route is not found in publicRoutes or privateRoutes, redirect to 404
      navigate("/404");
    }
  }, [location]);

  return (
    <div>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/standings" element={<Standings />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/about" element={<Gallery />} />
          <Route path="/competitions" element={<Competitions />} />
          <Route path="/competitions/open" element={<OpenAndJunior />} />
          <Route path="/competitions/tournaments" element={<Tournaments />} />
          <Route path="/contact-us" element={<Inquries />} />
          <Route path="/register" element={<SignUp />} />
        </Route>

        {/* Protect the following routes Only allow ADMIN */}
        <Route
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              allowedRoles={["ADMIN"]}
              userRole={user.role}
            />
          }
        >
          <Route
            path="/dashboard"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <AdminDashboardPage />
              </Suspense>
            }
          />

          <Route
            path="/members"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <Members />
              </Suspense>
            }
          />

          <Route
            path="/members/create"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <CreateMember />
              </Suspense>
            }
          />
          <Route
            path="/members/update/:memberId"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <SingleMember />
              </Suspense>
            }
          />

          <Route
            path="/memberships"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <Memberships />
              </Suspense>
            }
          />
          <Route
            path="/memberships/create"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <CreateMemberships />
              </Suspense>
            }
          />
          <Route
            path="/memberships/update/:membershipId"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <UpdateMemberships />
              </Suspense>
            }
          />

          <Route
            path="/events/"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <Events />
              </Suspense>
            }
          />
          <Route
            path="/events/update/:id"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <UpdateEvent />
              </Suspense>
            }
          />
          <Route
            path="/events/create"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <CreateEvent />
              </Suspense>
            }
          />
          <Route
            path="/events/create-category"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <CreateEventType />
              </Suspense>
            }
          />

          <Route
            path="/transactions/:id"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <Transaction />
              </Suspense>
            }
          />

          <Route
            path="/users"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <AllUsersPage />
              </Suspense>
            }
          />

          <Route
            path="/users/update/:id"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <SingleUserEditPage />
              </Suspense>
            }
          />

          <Route
            path="/users/create"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <AddUserPage />
              </Suspense>
            }
          />

          <Route
            path="/teams/"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <AllTeamsPage />
              </Suspense>
            }
          />

          <Route
            path="/teams/update/:id"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <EditSingleTeam />
              </Suspense>
            }
          />

          <Route
            path="/teams/members"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <AllTeamMemberPage />
              </Suspense>
            }
          />

          <Route
            path="/teams/members/update/:id"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <EditSingleTeamMemberPage />
              </Suspense>
            }
          />

          <Route
            path="/matches/"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <AllMatchesPage />
              </Suspense>
            }
          />

          <Route
            path="/matches/match/:id"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <SingleMatchesTabPage />
              </Suspense>
            }
          />

          <Route
            path="/matches/create/result"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <SingleMatchesTabPage />
              </Suspense>
            }
          />

          <Route
            path="/matches/create/score"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <CreateMatchScorePage />
              </Suspense>
            }
          />

          <Route
            path="/events/categories"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <AllEventCategoryPage />
              </Suspense>
            }
          />

          <Route
            path="/events/categories/update/:id"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <UpdateEventCategoryPage />
              </Suspense>
            }
          />

          <Route
            path="/dashboard/inquiry/:id"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <SingleInquiryPage />
              </Suspense>
            }
          />
        </Route>

        {/* Route for Assistant only */}

        <Route
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              allowedRoles={["ADMIN", "ASSISTANT"]}
              userRole={user.role}
            />
          }
        >
          <Route
            path="/events/upcoming"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <UpcomingEvents />
              </Suspense>
            }
          />
          <Route
            path="/events/upcoming/:id"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <EventSinglePayment />
              </Suspense>
            }
          />
        </Route>

        {/* Public route aka anyone who is logged in */}
        <Route
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              allowedRoles={["ADMIN", "ASSISTANT", "MEMBER"]}
              userRole={user.role}
            />
          }
        >
          <Route
            path="/user/profile/:id"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <UserProfilePage />
              </Suspense>
            }
          />

          <Route
            path="/announcements"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <AnnouncementsPage />
              </Suspense>
            }
          />

          <Route
            path="/member/events"
            element={
              <Suspense
                fallback={<LoadingOverlay loader={customLoader} visible />}
              >
                <MemberEventsPage />
              </Suspense>
            }
          />
        </Route>
        <Route path="*" element={<NothingFoundBackground />} />
      </Routes>
    </div>
  );
}

export default App;
