import {
  Alert,
  Button,
  Container,
  createStyles,
  Grid,
  Image,
  Paper,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { Link } from "react-router-dom";

import competitionsImage from "@/assets/competitions_image.webp";

import BylawsAccordion from "./BylawsAccordion";

const useStyles = createStyles(() => ({
  wrapper: {
    minHeight: "100vh",
    background: "linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)",
  },
}));

function Competitions() {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <Container>
        <Paper p="xl" shadow="xs">
          <Grid gutter="lg">
            <Grid.Col span={12} md={6}>
              <Image
                src={competitionsImage}
                alt="Volleyball"
                width="100%"
                height="auto"
              />
            </Grid.Col>

            <Grid.Col span={12} md={6}>
              <Text align="center" size="xl" weight={700}>
                Competitions
              </Text>
              <Text>
                Baulkham Hills Volleyball runs proper organised competitions.
              </Text>
              <Space h="md" />
              <Text>
                All teams playing in our Social Competitions should ensure they
                are familiar with the BHV Competition By-laws particularly with
                respect to FIVB rules, BHV Membership and Duty requirements.
              </Text>
              <Space h="md" />
              <Text>
                To find out more about BHV Social Competitions (both Open
                Social, Womens and Junior Social) or BHV Tournaments Contact us!
              </Text>
              <Space h="md" />
              <Grid.Col span={12}>
                <Button
                  component={Link}
                  fullWidth
                  to="/contact-us"
                  color="blue"
                >
                  Contact us
                </Button>
              </Grid.Col>
            </Grid.Col>
          </Grid>
          <Space h="md" />
          <Title ta={"center"} order={2} m="lg">
            COMPETITION BY-LAWS
          </Title>
          <Alert color={"yellow"} my={"my-1"}>
            <Text fw={600}>
              DISCLAIMER: The process of improving the competition is ongoing.
              These By-Laws are subject to changes. Always check with the Venue
              Manager before each season for the most current By-Laws.
            </Text>
          </Alert>
          <BylawsAccordion />
        </Paper>
      </Container>
    </div>
  );
}

export default Competitions;
