import { useMutation } from "@apollo/client";
import {
  Alert,
  Anchor,
  Button,
  Checkbox,
  Container,
  createStyles,
  Group,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  IconExclamationMark,
  IconMail,
  IconPassword,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { gql } from "@/__generated__/gql";
import loginBGImage from "@/assets/login_bg.avif";
import { authState, userState } from "@/store/Atoms";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    position: "relative",
    overflow: "hidden",

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: `url(${loginBGImage})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      filter: "blur(3px)",
      zIndex: -1,
    },
  },

  form: {
    borderRight: `3px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark : theme.colors.gray[3]
    }`,
    minHeight: "100vh",
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    width: 120,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const LOGIN = gql(/* GraphQL */ `
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      id
      name
      role
      status
      token
    }
  }
`);

export default function Login() {
  const { classes } = useStyles();
  const setUser = useSetRecoilState(userState);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const setIsAuthenticated = useSetRecoilState(authState);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: (val: string) =>
        val.length <= 0 ? "Email or username can't be empty" : null,
      //TODO: fix the length validation
      password: (val: string) =>
        val.length <= 2
          ? "Password should include at least 6 characters"
          : null,
    },
  });

  const [login, { loading, error }] = useMutation(LOGIN, {
    variables: {
      input: {
        username: form.values.email,
        password: form.values.password,
      },
    },
    onError: () => {
      // clear local storage on error just in case the user token is still there
      localStorage.clear();
    },
  });

  useEffect(() => {
    // clear local storage when user comes to login page
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (error?.message) {
      if (error?.message === "error logging in: user not found") {
        setErrMsg("Wrong email or password! Please try again.");
        setErr(true);
      } else if (error?.message === "error logging in: incorrect password") {
        setErrMsg("Wrong email or password! Please try again.");
        setErr(true);
      } else {
        setErrMsg("Unknown error! Please try again.");
        setErr(true);
      }
    }
  }, [error]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    // validate form
    const validate = form.validate();
    if (validate.hasErrors) {
      return;
    }

    login().then((res) => {
      if (res.data) {
        setUser({
          id: res.data.login.id,
          name: res.data.login.name,
          role: res.data.login.role,
          status: res.data.login.status,
          token: res.data.login.token,
        });
        localStorage.setItem("user", JSON.stringify(res.data.login));
        localStorage.setItem("token", res.data.login.token);
        setIsAuthenticated(true);
        navigate("/announcements");
      }
    });
  };

  return (
    <div>
      <Container>
        <div className={classes.wrapper}>
          <Paper className={classes.form} radius={0} p={30}>
            <form>
              <Title
                order={2}
                className={classes.title}
                align="center"
                mt="md"
                mb={30}
              >
                Welcome back!
              </Title>

              {err && (
                <Alert
                  className="mb-5"
                  icon={<IconExclamationMark size={20} />}
                  title="Bummer!"
                  color="red"
                  withCloseButton
                  closeButtonLabel="Close alert"
                  onClose={() => setErr(false)}
                >
                  <Text tt={"uppercase"} size="sm" color="red">
                    {errMsg}
                  </Text>
                </Alert>
              )}
              <TextInput
                required
                label="Email address or username"
                placeholder="hello@gmail.com | JohnDoe"
                size="md"
                icon={<IconMail size={20} />}
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue("email", event.currentTarget.value)
                }
                error={form.errors.email && "Email or username can't be empty"}
              />
              <PasswordInput
                required
                label="Password"
                placeholder="Your password"
                value={form.values.password}
                icon={<IconPassword size={20} />}
                mt="md"
                size="md"
                onChange={(event) =>
                  form.setFieldValue("password", event.currentTarget.value)
                }
                error={
                  form.errors.password &&
                  "Password should include at least 6 characters"
                }
              />
              <Group position="apart" mt="lg">
                <Checkbox label="Remember me" sx={{ lineHeight: 1 }} />
                <Anchor<"a">
                  onClick={(event) => event.preventDefault()}
                  href="#"
                  size="sm"
                >
                  Forgot password?
                </Anchor>
              </Group>
              <Button
                fullWidth
                mt="xl"
                className="bg-blue-500"
                type="submit"
                onClick={(event) => handleSubmit(event)}
                loaderPosition="right"
                loading={loading}
              >
                Sign in
              </Button>
            </form>
          </Paper>
        </div>
      </Container>
    </div>
  );
}
