import {
  Anchor,
  Center,
  Container,
  createStyles,
  Grid,
  Group,
  Image,
  rem,
  SimpleGrid,
  Skeleton,
  Spoiler,
  Text,
  Title,
} from "@mantine/core";
import { Parallax } from "react-scroll-parallax";

import activeKidsImage from "@/assets/active_kids_logo.webp";
import image from "@/assets/bhv_flyer.webp";
import { useIsMobile } from "@/hooks/useIsMobile";

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundImage: `linear-gradient(90deg, rgba(4,71,23,1) 0%, rgba(7,179,56,1) 100%)`,
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      flexDirection: "column",
      paddingTop: `calc(${theme.spacing.sm} * 2)`,
      paddingBottom: `calc(${theme.spacing.sm} * 2)`,
    },
  },

  content: {
    maxWidth: rem(500),
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  activeKidsBG: {
    backgroundColor: theme.colors.gray[0],
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.xl,
  },

  title: {
    color: `rgba(255, 255, 102)`,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(25),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("md")]: {
      fontSize: rem(18),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan("md")]: {
      display: "flex",
      marginTop: theme.spacing.xl,
    },
  },
}));

function HeroHeader() {
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  return (
    <Parallax speed={-10}>
      <div className={classes.wrapper}>
        <Container>
          <SimpleGrid
            cols={2}
            spacing="md"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <Grid>
              <Spoiler
                maxHeight={240}
                showLabel={
                  <>
                    <Text color="white" weight={700} mt={"sm"} ml={"xs"}>
                      Read More
                    </Text>
                  </>
                }
                hideLabel={
                  <>
                    <Text color="white" weight={700} ml={"xs"}>
                      Hide
                    </Text>
                  </>
                }
                initialState={false}
              >
                <Grid.Col span={12}>
                  <Title
                    className={classes.title}
                    italic
                    ta={"center"}
                    mt={"xl"}
                  >
                    Welcome to Baulkham Hills Volleyball!
                  </Title>
                  <Text color="white" mt="md" ta={"justify"}>
                    Baulkham Hills Volleyball (BHV) was set up by someone who
                    truly has a passion for the sport and devotes his time and
                    energy to volleyball through promoting, organising,
                    coaching, playing and sharing his experience and knowledge
                    with those who enjoy playing volleyball and those who would
                    like to learn.
                  </Text>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text color="white" ta={"justify"}>
                    No matter what level you play at, from beginner to elite,
                    you can come and join the club to play on either Sunday,
                    Monday, and Tuesday nights, or all three nights if
                    you&apos;re very keen! It&apos;s open to everyone. You can
                    enter your own team or ask to be placed in a team that needs
                    another player.
                  </Text>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Text color="white" ta={"justify"}>
                    BHV also provides a comprehensive JUNIOR program that
                    includes very popular Social Trainings and Competitions and
                    for the very committed BHV Junior players there is an
                    ADVANCED EXCEL (XL) Representative Training Program that
                    educates and ensures each player reaches their potential.
                  </Text>
                </Grid.Col>
              </Spoiler>
              <Group>
                <Container className={classes.activeKidsBG}>
                  <Grid grow gutter={0}>
                    <Grid.Col span={12} sm={12} md={12} lg={3}>
                      <Text
                        size={"md"}
                        transform="uppercase"
                        ta={"center"}
                        weight={800}
                        color="dimmed"
                        my={"sm"}
                        mr={"sm"}
                      >
                        bhv is an approved provider for the nsw govt active kids
                        program
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={12} sm={12} md={12} lg={3}>
                      <Center>
                        <Image src={activeKidsImage} width={170} height={170} />
                      </Center>
                    </Grid.Col>
                    <Grid.Col span={12} sm={12} md={12} lg={3}>
                      <Text
                        size={"md"}
                        transform="uppercase"
                        ta={"center"}
                        weight={800}
                        color="dimmed"
                        my={"sm"}
                        ml={"sm"}
                      >
                        <Anchor href="https://www.service.nsw.gov.au/transaction/apply-for-an-active-kids-voucher">
                          APPLY HERE
                        </Anchor>
                        &nbsp; for vouchers at service nsw and redeem at bhv
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Container>
              </Group>
            </Grid>
            <Group position="center">
              <Title className={classes.title} mt={"xl"}>
                BHV Activities at a Glance!
              </Title>
              <Image
                className="cursor-pointer"
                onClick={() => window.open(image)}
                src={image}
              />
            </Group>
          </SimpleGrid>
        </Container>
      </div>
    </Parallax>
  );
}

export default HeroHeader;
