import {
  Container,
  createStyles,
  Grid,
  Image,
  Paper,
  Space,
  Text,
  Title,
} from "@mantine/core";

const useStyles = createStyles(() => ({
  wrapper: {
    minHeight: "100vh",
    background: "linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)",
  },
}));

import social_juniors from "@/assets/social_junior.webp";
import social_open from "@/assets/social_open.webp";
import social_womens from "@/assets/social_womens.webp";

function OpenAndJunior() {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <Container mb={"md"}>
        <Title align="center" my={"md"}>
          Open & Social Competitions
        </Title>
        <Grid align="center">
          <Grid.Col span={12} md={6}>
            <Paper p="lg">
              <Title order={3} align="center" mb={"sm"}>
                Social - OPEN
              </Title>
              <Text mb={"md"}>
                BHV Social competitions are run on Sunday, Monday, and Tuesday
                nights all year round at the newly built 3 court gym of Baulkham
                Hills High School on Windsor Rd, Baulkham Hills.
              </Text>
              <Text mb={"md"}>
                The competitions are open to all ages and levels. We have A
                Grade for experienced players, B Grade for intermediate players,
                and C Grade for beginners.
              </Text>
              <Text mb={"md"}>
                Social trainings are available every Friday night from 8.30pm-
                11.00pm at North Ryde RSL, Youth Club, (located behind North Ryde
                RSL and opposite Fitness First).
              </Text>
              <Text mb={"md"}>
                The cost per session is $10 for BHV Members or $15 for Non-
                Members.
              </Text>
              <Space h={20} />
              <Title order={3} align="center" mb={"sm"}>
                Social - WOMEN&apos;S Competition
              </Title>
              <Text mb={"md"}>
                A womens competition has been established at BHV on Tuesday
                nights.
              </Text>

              <Text>
                If you&apos;re female and would like to play against other
                females on a Social women&apos;s height net, then this is for
                you! No more being intimidated or being run over by the blokes,
                here you can hold your own and play your position!
              </Text>

              <Space h={20} />
              <Title order={3} align="center" my={"md"}>
                Social - JUNIOR&apos;S Competition
              </Title>
              <Text mb={"md"}>
                At BHV we have established a Junior Social Competition on
                Sundays!!
              </Text>
              <Text>
                The Junior Social Competition is run straight after the
                3.30-5.30pm Junior Social Training. Starting at 5.30pm and
                currently finishing at 7.30pm.
              </Text>
              <Text mb={"md"}>
                Juniors from all schools such as Baulkham Hills, Pennant
                Hills, Castle Hill, James Ruse Agricultural, Normanhurst,
                Tangara, St Marks and Carlingford Highs mingle and enjoy
                playing in a fun and friendly environment.
              </Text>
              <Text mb={"md"}>
                If school volleyball is not enough for you, then come with your
                friends to where all the cool Junior volleyballers hang out!!
              </Text>
              <Text mb={"md"}>
                The BHV Junior Social Competitions caters for all levels with A, B and C
                grades on offer depending on playing level.
              </Text>
              <Text mb={"md"}>
                Boys and Girls from Beginner to Elite can join up with their own
                team or as as individuals. Now there is no excuse to hit the
                volleyball courts and get among the action!
              </Text>
            </Paper>
          </Grid.Col>
          <Grid.Col span={12} md={6} className="max-w-[420px]">
            <Image
              src={social_open}
              alt="Social Open Competition"
              width="100%"
              height="auto"
              my={"sm"}
            />
            <Image
              src={social_womens}
              alt="Social Women Competition"
              width="100%"
              height="auto"
              my={"sm"}
            />
            <Image
              src={social_juniors}
              alt="Social Junior Competition"
              width="100%"
              height="auto"
              my={"sm"}
            />
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
}

export default OpenAndJunior;
