import { Icon } from "@iconify/react";
import {
  Box,
  Burger,
  Button,
  Center,
  Collapse,
  Container,
  createStyles,
  Divider,
  Drawer,
  Group,
  Header,
  HoverCard,
  Paper,
  ScrollArea,
  SimpleGrid,
  Text,
  ThemeIcon,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import logo from "@/assets/banner.png";
import { authStateSelector } from "@/store/Atoms";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: 42,
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
  },

  subLink: {
    width: "100%",
    padding: theme.spacing.sm,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: -theme.spacing.md,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md}px ${theme.spacing.md}px`,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));

const mockdata = [
  {
    icon: "fa-solid:volleyball-ball",
    title: "Open & Juniors",
    description: "Open social/junior volleyball.",
    link: "/competitions/open",
  },
  {
    icon: "mdi:trophy",
    title: "Tournaments",
    description: "View current/previous tournaments.",
    link: "/competitions/tournaments",
  },
];

function NavBar() {
  const isAuthenticated = useRecoilValue(authStateSelector);
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const { classes, theme } = useStyles();
  const navigate = useNavigate();

  const links = mockdata.map((item) => (
    <Link to={item.link} key={item.title}>
      <UnstyledButton className={classes.subLink} key={item.title}>
        <Group noWrap align="flex-start">
          <ThemeIcon size={34} variant="default" radius="md">
            <Icon icon={item.icon} width={22} />
          </ThemeIcon>
          <div>
            <Text size="sm" weight={500}>
              {item.title}
            </Text>
            <Text size="xs" color="dimmed">
              {item.description}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    </Link>
  ));

  return (
    <Box pb={0} className="">
      <Header height={70} px="md">
        <Group position="apart" sx={{ height: "100%" }}>
          <Link to="/">
            <img
              src={logo}
              alt="Baulkham Hills Logo"
              style={{ width: "65px" }}
            />
          </Link>
          <Group
            sx={{ height: "100%" }}
            spacing={0}
            className={classes.hiddenMobile}
            position="apart"
          >
            <Link to="/" className={classes.link}>
              Home
            </Link>
            <HoverCard
              width={600}
              position="bottom"
              radius="md"
              shadow="md"
              withinPortal
            >
              <HoverCard.Target>
                <Link
                  to={isMobile ? "#" : "/competitions"}
                  className={classes.link}
                >
                  <Center inline>
                    <Box component="span" mr={5}>
                      Competitions
                    </Box>
                    <Icon icon="tabler:chevron-down" width={16} />
                  </Center>
                </Link>
              </HoverCard.Target>

              <HoverCard.Dropdown sx={{ overflow: "hidden" }}>
                <Group position="apart" px="md">
                  <Text weight={500}>Competitions</Text>
                </Group>
                <Divider
                  my="sm"
                  mx="-md"
                  color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
                />

                <SimpleGrid cols={2} spacing={0}>
                  {links}
                </SimpleGrid>
              </HoverCard.Dropdown>
            </HoverCard>
            <Link to="/gallery" className={classes.link}>
              Gallery
            </Link>

            <Link to={"/contact-us"} className={classes.link}>
              Contact Us
            </Link>
          </Group>

          <Group position="right" className={classes.hiddenMobile}>
            {isAuthenticated ? (
              <Button
                variant="default"
                onClick={() => navigate("/announcements")}
              >
                Dashboard
              </Button>
            ) : (
              <Button variant="default" onClick={() => navigate("/auth/login")}>
                Log in
              </Button>
            )}

            <span>
              <Button component={Link} to="/register ">
                Sign up
              </Button>
            </span>
          </Group>

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className={classes.hiddenDesktop}
          />
        </Group>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea sx={{ height: "calc(100vh - 60px)" }} mx="-md">
          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          <Link to="/" className={classes.link}>
            Home
          </Link>
          <UnstyledButton className={classes.link} onClick={toggleLinks}>
            <Center inline>
              <Box component="span" mr={5}>
                Competitions
              </Box>
              <Icon icon="tabler:chevron-down" width={16} />
            </Center>
          </UnstyledButton>
          <Collapse in={linksOpened}>
            <Paper shadow={"sm"} radius={"md"}>
              <Container>{links}</Container>
            </Paper>
          </Collapse>
          {/* <Link to="/about-us" className={classes.link}>
            About Us
          </Link> */}

          <Link to={"/contact-us"} className={classes.link}>
            Contact Us
          </Link>

          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          <Group position="center" grow pb="xl" px="md">
            <Button variant="default" onClick={() => navigate("/auth/login")}>
              Log in
            </Button>
            <Button component={Link} to="/register">
              Sign up
            </Button>
          </Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}

export default NavBar;
