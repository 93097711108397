import { useMutation } from "@apollo/client";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {
  ActionIcon,
  Button,
  createStyles,
  Grid,
  Group,
  rem,
  SimpleGrid,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import {
  IconBook,
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTwitter,
  IconCheck,
  IconExclamationMark,
  IconMail,
  IconPaperBag,
  IconPhone,
} from "@tabler/icons-react";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useRef } from "react";

import { gql } from "@/__generated__/gql";
import { InquiriesStatus } from "@/__generated__/graphql";
import whiteWallBG from "@/assets/white_wall_hash.webp";

import { ContactIconsList } from "./ContactIcons";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "50vh",
    boxSizing: "border-box",
    backgroundImage: `linear-gradient(-60deg, ${
      theme.colors[theme.primaryColor][4]
    } 0%, ${theme.colors[theme.primaryColor][7]} 100%)`,
    borderRadius: theme.radius.md,
    padding: `calc(${theme.spacing.lg} * 2.5)`,

    // Set width to 100% for mobile devices
    width: "100%",

    [theme.fn.smallerThan("sm")]: {
      padding: `calc(${theme.spacing.xl} * 1.5)`,
    },

    [theme.fn.largerThan("md")]: {
      width: "1000px",
    },
  },

  containerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundImage: `url(${whiteWallBG})`,
    backgroundSize: "cover",
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.white,
    lineHeight: 1,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    maxWidth: rem(300),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
  },

  social: {
    color: theme.white,

    "&:hover": {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    "&::placeholder": {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,
  },

  control: {
    backgroundColor: theme.colors[theme.primaryColor][6],
  },
}));

const social = [
  {
    name: "Facebook",
    icon: IconBrandFacebook,
    link: "https://www.facebook.com/groups/Baulkhamhillsvolleyball/",
  },
  {
    name: "Instagram",
    icon: IconBrandInstagram,
    link: "https://www.instagram.com/baulkhamhillsvolleyball/",
  },
  {
    name: "Twitter",
    icon: IconBrandTwitter,
    link: "https://www.twitter.com/baulkhamhillsvolleyball",
  },
];

const CREATE_INQUIRY = gql(/* GraphQL */ `
  mutation CreateInquiry($input: CreateInquiriesInput!) {
    createInquiry(input: $input) {
      id
      name
      email
      phone
      subject
      message
    }
  }
`);

function InfoSection() {
  const { classes } = useStyles();

  const captchaRef = useRef<HCaptcha>(null);

  // map social icons
  const icons = social.map((social) => (
    <ActionIcon
      key={social.name}
      size={28}
      className={classes.social}
      variant="transparent"
      onClick={() => {
        window.open(social.link, "_blank", "noopener,noreferrer");
      }}
    >
      <social.icon size="1.4rem" stroke={1.5} />
    </ActionIcon>
  ));

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      status: InquiriesStatus.Active,
      hcaptchaToken: "",
    },
    validate: {
      name: (val: string) => {
        const isEmpty = val.length === 0;

        if (isEmpty) {
          return "Name can't be empty";
        }

        return null;
      },
      email: (val: string) => {
        const isEmpty = val.length === 0;
        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);

        if (isEmpty) {
          return "Email can't be empty";
        }

        if (!isEmailValid) {
          return "Email is not valid";
        }

        return null;
      },
      phone: (val: string) => {
        const isEmpty = val.length === 0;

        if (isEmpty) {
          return "Phone can't be empty";
        }

        if (!isValidPhoneNumber(val, "AU")) {
          return "Phone number is not valid";
        }

        return null;
      },
      subject: (val: string) => {
        const isEmpty = val.length === 0;

        if (isEmpty) {
          return "Subject can't be empty";
        }

        return null;
      },
      message: (val: string) => {
        const isEmpty = val.length === 0;

        if (isEmpty) {
          return "Message can't be empty";
        }

        return null;
      },
    },
  });

  const [createInquiry, { loading: creating }] = useMutation(CREATE_INQUIRY, {
    variables: {
      input: {
        name: form.values.name,
        email: form.values.email,
        phone: form.values.phone.replace(/\s/g, ""), // Remove whitespace
        message: form.values.message,
        subject: form.values.subject,
        status: form.values.status,
        hcaptchaToken: form.values.hcaptchaToken,
      },
    },
    onCompleted: () => {
      showNotification({
        title: "Success",
        message: `We received your inquiry! We will get back to you within 1 to 5 buisness days.`,
        color: "teal",
        icon: <IconCheck />,
        autoClose: true,
      });
      form.reset();
    },
    onError: (error) => {
      showNotification({
        title: "Error",
        message: error.message,
        color: "red",
        icon: <IconExclamationMark />,
        autoClose: 10000,
      });
      // Reset captcha
      if (captchaRef.current) {
        captchaRef.current.resetCaptcha();
      }
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedValue = value;

    if (name === "phone") {
      updatedValue = value
        .replace(/\D+/g, "") // Remove non-digit characters
        .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, (match, p1, p2, p3) => {
          let formatted = p1;
          if (p2) formatted += " " + p2;
          if (p3) formatted += " " + p3;
          return formatted;
        });

      form.setFieldValue("phone", updatedValue);
    }
  };

  return (
    <div className={classes.containerWrapper}>
      <div className={classes.wrapper}>
        <form
          onSubmit={(event) => {
            event.preventDefault(); // Prevent the default form submission behavior
            // validate the form here
            if (form.validate().hasErrors) {
              return;
            }
            createInquiry();
          }}
        >
          <SimpleGrid
            cols={2}
            spacing={50}
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <div>
              <Title className={classes.title}>Contact us</Title>
              <Text className={classes.description} mt="sm" mb={30}>
                Leave your email and phone we will get back to you within 24
                hours
              </Text>

              <ContactIconsList variant="white" />

              <Group mt="xl">{icons}</Group>
            </div>

            <div className={classes.form}>
              <Grid gutter={10}>
                <Grid.Col span={12}>
                  <TextInput
                    name="name"
                    label="Name"
                    placeholder="Enter your name"
                    required
                    value={form.values.name}
                    variant="filled"
                    icon={<IconPaperBag size={16} />}
                    onChange={(event) =>
                      form.setFieldValue("name", event.currentTarget.value)
                    }
                    error={form.errors.name}
                    classNames={{
                      input: classes.input,
                      label: classes.inputLabel,
                    }}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <TextInput
                    name="email"
                    label="Email"
                    placeholder="example@gmail.com"
                    required
                    value={form.values.email}
                    variant="filled"
                    icon={<IconMail size={16} />}
                    onChange={(event) =>
                      form.setFieldValue("email", event.currentTarget.value)
                    }
                    error={form.errors.email}
                    classNames={{
                      input: classes.input,
                      label: classes.inputLabel,
                    }}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <TextInput
                    name="phone"
                    label="Phone"
                    placeholder="0412 345 678"
                    value={form.values.phone}
                    icon={<IconPhone size={16} />}
                    onChange={(event) => handleChange(event)}
                    type="tel"
                    required
                    error={form.errors.phone}
                    classNames={{
                      input: classes.input,
                      label: classes.inputLabel,
                    }}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <TextInput
                    name="subject"
                    label="Subject"
                    placeholder="Social Training, Social Competition, other.."
                    value={form.values.subject}
                    icon={<IconBook size={16} />}
                    onChange={(event) =>
                      form.setFieldValue("subject", event.currentTarget.value)
                    }
                    required
                    error={form.errors.subject}
                    classNames={{
                      input: classes.input,
                      label: classes.inputLabel,
                    }}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Textarea
                    mt="md"
                    label="Message"
                    placeholder="Your message. Please indicate if JUNIOR or ADULT."
                    maxRows={10}
                    minRows={5}
                    required
                    autosize
                    name="message"
                    variant="filled"
                    value={form.values.message}
                    onChange={(event) =>
                      form.setFieldValue("message", event.currentTarget.value)
                    }
                    classNames={{
                      input: classes.input,
                      label: classes.inputLabel,
                    }}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <HCaptcha
                    ref={captchaRef}
                    size="normal"
                    sitekey={import.meta.env.VITE_HCAPTCHA_SITE_KEY}
                    onVerify={(token) => {
                      form.setFieldValue("hcaptchaToken", token);
                    }}
                  />
                </Grid.Col>
              </Grid>

              <Group position="right" mt="md">
                <Button
                  type="submit"
                  className={classes.control}
                  loaderPosition="center"
                  loading={creating}
                >
                  Send message
                </Button>
              </Group>
            </div>
          </SimpleGrid>
        </form>
      </div>
    </div>
  );
}

export default InfoSection;
