import { useMutation } from "@apollo/client";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {
  Button,
  createStyles,
  Grid,
  Group,
  PasswordInput,
  Progress,
  rem,
  SimpleGrid,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import {
  IconCheck,
  IconExclamationMark,
  IconMail,
  IconPaperBag,
  IconPassword,
  IconPhone,
} from "@tabler/icons-react";
import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import zxcvbnEnPackage from "@zxcvbn-ts/language-en";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useEffect, useRef, useState } from "react";

import { gql } from "@/__generated__/gql";
import { UsersRole, UsersStatus } from "@/__generated__/graphql";
import whiteWallBG from "@/assets/white_wall_hash.webp";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "50vh",
    boxSizing: "border-box",
    backgroundImage: `linear-gradient(-60deg, ${
      theme.colors[theme.primaryColor][4]
    } 0%, ${theme.colors[theme.primaryColor][7]} 100%)`,
    borderRadius: theme.radius.md,
    padding: `calc(${theme.spacing.lg} * 2.5)`,

    // Set width to 100% for mobile devices
    width: "100%",

    [theme.fn.smallerThan("sm")]: {
      padding: `calc(${theme.spacing.xl} * 1.5)`,
    },

    [theme.fn.largerThan("md")]: {
      width: "1000px",
    },
  },

  containerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundImage: `url(${whiteWallBG})`,
    backgroundSize: "cover",
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.white,
    lineHeight: 1,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    maxWidth: rem(300),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
  },

  social: {
    color: theme.white,

    "&:hover": {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    "&::placeholder": {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,
  },

  control: {
    backgroundColor: theme.colors[theme.primaryColor][6],
  },
}));

const USERS_SIGNUP = gql(/* GraphQL */ `
  mutation CreateUserPublicSignUp($input: CreateUsersInput!) {
    createUser(input: $input) {
      id
      name
      username
      email
      member {
        id
      }
    }
  }
`);

function SignUp() {
  const { classes } = useStyles();

  const [passwordStrength, setPasswordStrength] = useState(0);
  const captchaRef = useRef<HCaptcha>(null);

  const form = useForm({
    initialValues: {
      name: "",
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "04",
      hcaptchaToken: "",
    },
    validate: {
      name: (val: string) => {
        const isEmpty = val.length === 0;
        const hasValidLength = val.length >= 3;

        if (isEmpty) {
          return "Name can't be empty";
        }

        if (!hasValidLength) {
          return "Name should include at least 3 characters";
        }

        return null;
      },
      email: (val: string) => {
        const isEmpty = val.length === 0;
        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);

        if (isEmpty) {
          return "Email can't be empty";
        }

        if (!isEmailValid) {
          return "Email is not valid";
        }

        return null;
      },
      phone: (val: string) => {
        const isEmpty = val.length === 0;

        if (isEmpty) {
          return "Phone can't be empty";
        }

        if (!isValidPhoneNumber(val, "AU")) {
          return "Phone number is not valid";
        }

        return null;
      },

      username: (val: string) => {
        const isEmpty = val.length === 0;
        const hasValidLength = val.length >= 3;
        const hasNoSpaces = !val.includes(" ");
        const hasNoSpecialChars = /^[a-zA-Z0-9_]*$/.test(val);

        if (isEmpty) {
          return "Username is required";
        }
        if (!hasValidLength) {
          return "Username should include at least 3 characters";
        }
        if (!hasNoSpaces) {
          return "Username should not contain spaces";
        }
        if (!hasNoSpecialChars) {
          return "Username should only contain letters, numbers, and underscores";
        }
        return null;
      },
      password: (val: string) => {
        const hasValidLength = val.length >= 8;
        const hasNoSpaces = !val.includes(" ");
        const hasUpperCase = /[A-Z]/.test(val);
        const hasLowerCase = /[a-z]/.test(val);
        const hasNumber = /\d/.test(val);

        if (!hasValidLength) {
          return "Password should include at least 8 characters";
        }
        if (!hasNoSpaces) {
          return "Password should not contain spaces";
        }
        if (!hasUpperCase) {
          return "Password should contain at least one uppercase letter";
        }
        if (!hasLowerCase) {
          return "Password should contain at least one lowercase letter";
        }
        if (!hasNumber) {
          return "Password should contain at least one number";
        }

        return null;
      },
      confirmPassword: (val: string) => {
        const password = form.values.password as string;
        return val === password ? null : "Passwords do not match";
      },
    },
  });

  const [createSignUpUser, { loading: creating, error }] = useMutation(
    USERS_SIGNUP,
    {
      variables: {
        input: {
          name: form.values.name,
          username: form.values.username,
          email: form.values.email,
          password: form.values.password,
          role: UsersRole.Member,
          phone: form.values.phone.replace(/\s/g, ""), // Remove whitespace
          status: UsersStatus.Active,
          hcaptchaToken: form.values.hcaptchaToken,
        },
      },
      onCompleted: () => {
        showNotification({
          title: "Success",
          message: `Registration successful, please check your email for verification.`,
          color: "teal",
          icon: <IconCheck />,
          autoClose: true,
        });
        form.reset();
      },
      onError: (error) => {
        showNotification({
          title: "Error",
          message: error.message,
          color: "red",
          icon: <IconExclamationMark />,
          autoClose: 10000,
        });
        // Reset captcha
        if (captchaRef.current) {
          captchaRef.current.resetCaptcha();
        }
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedValue = value;

    if (name === "phone") {
      updatedValue = value
        .replace(/\D+/g, "") // Remove non-digit characters
        .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, (match, p1, p2, p3) => {
          let formatted = p1;
          if (p2) formatted += " " + p2;
          if (p3) formatted += " " + p3;
          return formatted;
        });

      form.setFieldValue("phone", updatedValue);
    }
  };

  useEffect(() => {
    updatePasswordStrength(form.values.password as string);
  }, [form.values.password]);

  const updatePasswordStrength = (password: string) => {
    const options = {
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
      },
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      translations: zxcvbnEnPackage.translations,
    };
    zxcvbnOptions.setOptions(options);
    const result = zxcvbn(password);
    setPasswordStrength(result.score);
  };

  return (
    <div className={classes.containerWrapper}>
      <div className={classes.wrapper}>
        <form
          onSubmit={(event) => {
            event.preventDefault(); // Prevent the default form submission behavior
            // validate the form here
            if (form.validate().hasErrors) {
              return;
            }
            createSignUpUser();
          }}
        >
          <SimpleGrid
            cols={1}
            spacing={"xl"}
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <div>
              <Title className={classes.title} ta="center">
                Register for an account
              </Title>
            </div>

            <div className={classes.form}>
              <Grid gutter={10}>
                <Grid.Col span={12}>
                  <TextInput
                    name="name"
                    label="Name"
                    placeholder="John Smith"
                    required
                    value={form.values.name}
                    variant="filled"
                    icon={<IconPaperBag size={16} />}
                    onChange={(event) =>
                      form.setFieldValue("name", event.currentTarget.value)
                    }
                    error={form.errors.name}
                    classNames={{
                      input: classes.input,
                      label: classes.inputLabel,
                    }}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <TextInput
                    name="username"
                    label="Username"
                    description="This will be your public username, it has to be unique and cannot be changed later! Make sure it's appropriate."
                    placeholder="johnsmith123"
                    value={form.values.username}
                    icon={<IconPaperBag size={16} />}
                    onChange={(event) =>
                      form.setFieldValue("username", event.currentTarget.value)
                    }
                    required
                    error={form.errors.username}
                    classNames={{
                      input: classes.input,
                      label: classes.inputLabel,
                    }}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <TextInput
                    name="email"
                    label="Email"
                    description="Email address have to be unique and cannot be changed later!"
                    placeholder="example@gmail.com"
                    required
                    value={form.values.email}
                    variant="filled"
                    icon={<IconMail size={16} />}
                    onChange={(event) =>
                      form.setFieldValue("email", event.currentTarget.value)
                    }
                    error={form.errors.email}
                    classNames={{
                      input: classes.input,
                      label: classes.inputLabel,
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    name="phone"
                    label="Phone"
                    description="Enter the phone number linked to your membership"
                    placeholder="0412 345 678"
                    value={form.values.phone}
                    icon={<IconPhone size={16} />}
                    onChange={(event) => handleChange(event)}
                    type="tel"
                    required
                    error={form.errors.phone}
                    classNames={{
                      input: classes.input,
                      label: classes.inputLabel,
                    }}
                  />
                </Grid.Col>

                <Grid.Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <PasswordInput
                    required
                    label="New Password"
                    placeholder="New password"
                    value={form.values.password}
                    icon={<IconPassword size={20} />}
                    onChange={(event) => {
                      form.setFieldValue("password", event.currentTarget.value);
                    }}
                    error={form.errors.password}
                  />
                  <Progress
                    mt={"xs"}
                    size="xl"
                    radius={"xl"}
                    value={Math.max(passwordStrength * 25, 15)}
                    color={
                      passwordStrength === 0
                        ? "gray"
                        : passwordStrength === 1
                        ? "red"
                        : passwordStrength === 2
                        ? "yellow"
                        : passwordStrength === 3
                        ? "cyan"
                        : "green"
                    }
                    label={
                      passwordStrength === 0
                        ? "Very Weak"
                        : passwordStrength === 1
                        ? "Weak"
                        : passwordStrength === 2
                        ? "Medium"
                        : passwordStrength === 3
                        ? "Strong"
                        : "Very Strong"
                    }
                  />
                </Grid.Col>

                <Grid.Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <PasswordInput
                    required
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    value={form.values.confirmPassword}
                    icon={<IconPassword size={20} />}
                    onChange={(event) =>
                      form.setFieldValue(
                        "confirmPassword",
                        event.currentTarget.value
                      )
                    }
                    error={form.errors.confirmPassword}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <HCaptcha
                    ref={captchaRef}
                    size="normal"
                    sitekey={import.meta.env.VITE_HCAPTCHA_SITE_KEY}
                    onVerify={(token) => {
                      form.setFieldValue("hcaptchaToken", token);
                    }}
                  />
                </Grid.Col>
              </Grid>

              <Group position="right" mt="md">
                <Button
                  type="submit"
                  className={classes.control}
                  size="md"
                  loaderPosition="center"
                  loading={creating}
                  fullWidth
                >
                  Sign Up
                </Button>
              </Group>
            </div>
          </SimpleGrid>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
