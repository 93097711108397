import "@/index.css";

import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { createUploadLink } from "apollo-upload-client";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { RecoilRoot } from "recoil";

import App from "@/App";

const httpLink = createUploadLink({
  uri: import.meta.env.VITE_GRAPHQL_API_ENDPOINT as string,
  fetchOptions: {
    credentials: "include",
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Team: {
      fields: {
        teamMembers: {
          merge(existing, incoming) {
            // We can define your custom merge logic here.
            // This example simply replaces the existing data with the incoming data.
            return incoming;
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
});

const CUSTOM_THEME = {
  fontFamily: " Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
  // colorScheme: "dark",
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <MantineProvider theme={CUSTOM_THEME} withNormalizeCSS>
        <ModalsProvider>
          <Notifications
            position="top-right"
            zIndex={2077}
            transitionDuration={500}
            autoClose={6000}
          />
          <ApolloProvider client={client}>
            <ParallaxProvider>
              <RecoilRoot>
                <App />
              </RecoilRoot>
            </ParallaxProvider>
          </ApolloProvider>
        </ModalsProvider>
      </MantineProvider>
    </BrowserRouter>
  </React.StrictMode>
);
