import { Accordion, createStyles, List, Table } from "@mantine/core";
const BylawsAccordion = () => {
  const useStyles = createStyles(() => ({
    wrapper: {
      minHeight: "100vh",
      background: "linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)",
    },
    tableCell: {
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
  }));

  const { classes } = useStyles();

  const data = [
    [
      "Use of unregistered player 1st occasion",
      "Nil",
      "0 point per player per match and Forfeit of match",
    ],
    [
      "Use of unregistered player 2nd occasion",
      "$15",
      "0 point per player per match and Forfeit of match",
    ],
    [
      "Player out of uniform (if applicable)",
      "Nil",
      "1 point per player per match*",
    ],
    ["Forfeit of match less than 3 players", "$60", "3"],
    ["Forfeit of match 3 players", "$30", "2"],
    ["Forfeit of match 4 players", "$15", "1"],
    ["5 Players for match 1st occasion", "Nil", "0"],
    ["5 Players for match 2nd occasion", "Nil", "0"],
    ["5 Players for match 3rd occasion", "Nil", "1"],
    ["5 Players for match 4th occasion", "$25", "3"],
    [
      "Forfeit of duty for each person short of the 5 required for duty",
      "$5 per match",
      "1 point per match",
    ],
    ["Substandard performance of duty", "$15", "1"],
    [
      "Player or team issued with a second yellow card for competition",
      "$15",
      "0",
    ],
    ["Player or team issued with a Red Card (Expulsion)", "$15", "1"],
    [
      "Player or team issued with a Red Card & Yellow Card simultaneously (Disqualification)",
      "Set by Venue Manager",
      "Disciplinary action subject to the Venue Manager.",
    ],
  ];
  return (
    <Accordion>
      <Accordion.Item value="By-law Section 1">
        <Accordion.Control>1. INTRODUCTION</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="number">
            <List.Item>
              <p>
                Welcome to Baulkham Hills Volleyball (BHV). Please take time to
                read the information provided here. It will explain rules and
                responsibilities and help you and the other players to enjoy the
                competition.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 2">
        <Accordion.Control>2. RULES, REGULATIONS & POLICIES</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="number">
            <List.Item>
              <p>
                BHV competitions are run according to Federation Internationale
                De Volleyball (FIVB) Rules of the Game as adopted by the
                Australian Volleyball Federation (AVF), except where
                specifically amended by the By-laws set out in this document.
                Please make sure that all players in your team read and
                understand them. All players are encouraged to obtain the
                official FIVB Rule book and familiarise themselves with the
                rules & protocols of Volleyball.
              </p>
            </List.Item>

            <List.Item>
              <p>
                BHV advises that its venue, (Baulkham Hills High School Gym)
                does not comply with all FIVB regulations with respect to the
                playing area. BHV takes no responsibility for injuries caused
                due to these irregularities.
              </p>
            </List.Item>

            <List.Item>
              <p>
                BHV has in place member protection policies and a qualified
                Member Protection Information Officer (MPIO) is also available
                to advise on issues pertaining to BHV incidents or matters, as
                per NSW Dept of Sport and Recreation guidelines.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 3">
        <Accordion.Control>3. MEMBERSHIP & INJURIES</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="number">
            <List.Item>
              <p>
                All players must have a current Membership with BHV before they
                enter a court for training or competition. BHV Membership
                provides players with insurance coverage in the event they are
                injured, or injure others during a game or training session.
                (See By-Law 6.6)
              </p>
            </List.Item>

            <List.Item>
              <p>
                All injuries that take place during a match (including official
                warm up) must be recorded on the scoresheet immediately by the
                duty team in the “remarks” section and the venue manager must be
                advised of this incident. E.g. Injury to player number 8 from
                Team A (L. Ankle), second set, at 9-15. Injuries at training
                must be reported immediately to the coach in charge of the
                training session.
              </p>
            </List.Item>
            <List listStyleType={"disc"} withPadding>
              <List.Item>
                <p>
                  New players are required to complete and sign a BHV Membership
                  form. If they are only interested in trialling the competition
                  then they can select the ‘Trial Membership’ option on the
                  form. This includes a temporary membership for two social
                  games or two social trainings, after which full membership
                  will be required.
                </p>
              </List.Item>
            </List>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 4">
        <Accordion.Control>4. TEAM BOND</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="number">
            <List.Item>
              <p>
                The aim of the team bond is to encourage teams to comply with
                the By-laws; behave in a responsible manner and establish a
                commitment to the competition & respect for other players,
                officials and spectators.
              </p>
            </List.Item>

            <List.Item>
              <p>
                Each team must lodge an $80 bond with BHV upon lodging an entry
                form for any competition. At the end of each competition the
                bond is refundable, or can be carried over to the next
                competition. If the team has not fully complied with the
                By-laws, bond deductions may be incurred and a reduced portion
                of the bond will be refunded.
              </p>
            </List.Item>

            <List.Item>
              <p>
                The team bond will be reduced in part or in full according to
                the scales in By-law 15.4
              </p>
            </List.Item>

            <List.Item>
              <p>
                The venue manager will notify the team representative or their
                substitute of the infringement. The team must replace the lost
                bond before the Finals of the current competition.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 5">
        <Accordion.Control>5. LATE ENTRY PENALTY PAYMENT</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="number">
            <List.Item>
              <p>
                When applicable a late entry penalty fee of $80 will be payable
                with any entry form not lodged by the closing date for entries.
                Note: Payments are to accompany entry forms.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 6">
        <Accordion.Control>6. UNIFORMS</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="number">
            <List.Item>
              <p>
                All team members are encouraged to be in uniform by the 4th week
                of competition. After the 4th week, teams may be sanctioned or a
                bonus applied as laid down in By-law 15.4.
              </p>
            </List.Item>

            <List.Item>
              <p>
                The team uniform shall consist of matching shirts of the same
                colour & style with permanent numbers of contrasting colour at
                least 2 cm thick & 10 cm in size placed on the front and at
                least 2 cm thick & 20 cm in size on the back of the shirt. All
                players in a team are encouraged to wear shorts of the same
                colour.
              </p>
            </List.Item>

            <List.Item>
              <p>
                Teams may apply to the venue manager for exemption from By-law
                6.2 where proof of uniforms on order can be produced.
              </p>
            </List.Item>

            <List.Item>
              <p>
                Non-marking sport shoes must be worn during competition and
                training and NO player is permitted to play without shoes under
                any circumstances.
              </p>
            </List.Item>

            <List.Item>
              <p>
                BHV advises all players not to wear jewellery or watches while
                playing volleyball as this may cause injury to you or others.
                BHV takes no responsibility for injuries caused or worsened by
                wearing jewellery or watches, or for damage to the jewellery or
                watch itself. Players who disregard this advice are not covered
                by BHV &apos;s insurance policy for any of these situations in
                which wearing jewellery or a watch contributes to an incident.
                Further, the player may be liable in circumstances where wearing
                jewellery or a watch contributes to an incident involving
                another player.
              </p>
            </List.Item>

            <List.Item>
              <p>
                A player who substitutes for a team for one match to avoid a
                forfeited match does not have to be in that team&apos;s uniform.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 7">
        <Accordion.Control>7. TEAM FORMAT</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="number">
            <List.Item>
              <p>
                Upon entry into the competition, all teams must nominate a
                minimum of seven and a maximum of ten players, all of whom have
                current BHV Membership.
              </p>
            </List.Item>

            <List.Item>
              <p>
                Teams in BHV’s ‘Open’ competitions may consist of any number of
                male or female players.
              </p>
            </List.Item>

            <List.Item>
              <p>
                Teams with five players will be sanctioned as laid down in
                By-law 15.4.
              </p>
            </List.Item>

            <List.Item>
              <p>
                All teams are permitted to play with five players on the court.
                A gap must be nominated in place of the missing sixth player in
                the team&apos;s rotation line-up. The service shall be forfeited
                when the gap rotates to the serving position and a rally point
                allocated to the opposition.
              </p>
            </List.Item>

            <List.Item>
              <p>
                Teams with only five players are not usually permitted to borrow
                a sixth player from another team that plays on the same night of
                competition. This includes borrowing players from lower grades.
                However, it is possible with the permission of the
                opposition&apos;s captain AND the Venue Manager.
              </p>
            </List.Item>

            <List.Item>
              <p>
                A team short on players may borrow one or two players from a
                lower grade on the same night of competition, except in the
                lowest grade where player/s from the same grade may play.
                However, the team must play with a maximum of five players,
                unless approval is given by the Venue Manager, after
                consultation with the opposing team. See By-law 7.3 and 7.5.
              </p>
            </List.Item>

            <List.Item>
              <p>
                A player may play in a higher grade a maximum of four times
                during the same competition. On the fifth occasion, that player
                will become ineligible to return to the lower grade for the
                remainder of that competition. Exemptions from this By-law may
                be granted only with the approval of the Venue Manager, who will
                determine the situation on its merits; however, that player will
                still need to meet requirements set down in By-laws 13.1, 13.2,
                and 13.3.
              </p>
            </List.Item>

            <List.Item>
              <p>
                New players or players from other clubs are welcome, subject to
                the Venue Manager&apos;s approval. However, any new player must
                fill in a Full Membership or Trial Membership form in accordance
                with By-law 3.2.1.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 8">
        <Accordion.Control>8. PLAYER TRANSFERS</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="number">
            <List.Item>
              <p>
                A player may only play in one team per competition night except
                when playing as a &quot;borrowed&quot; player as described under
                By-law 7.6, unless an exemption has been granted under By-law
                7.7.
              </p>
            </List.Item>

            <List.Item>
              <p>
                A player may transfer from one team to another once they receive
                clearance from the team representative of the team they wish to
                leave. The team representative granting clearance should ensure
                that the player does not owe money or uniform to the team.
              </p>
            </List.Item>

            <List.Item>
              <p>
                Once receiving clearance, the player must apply to the venue
                manager for a transfer. The Venue Manager must within one week
                approve or refuse the transfer of the player and notify that
                player.
              </p>
            </List.Item>

            <List.Item>
              <p>A player may transfer only once per competition.</p>
            </List.Item>

            <List.Item>
              <p>
                The Venue Manager may refuse a transfer to any player without
                explanation.
              </p>
            </List.Item>

            <List.Item>
              <p>
                The Venue Manager, at their discretion, may restrict or prohibit
                players from playing in a lower grade than they are capable, or
                in a higher grade than they are capable.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 9">
        <Accordion.Control>9. PLAYER BEHAVIOUR</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="number">
            <List.Item>
              <p>
                BHV aims to promote sportsmanlike conduct in all its
                competitions and trainings.
              </p>
            </List.Item>

            <List.Item>
              <p>
                Only the Game Captains are to communicate with the referee, and
                other officials, and must do so in accordance with correct
                protocols stipulated in the FIVB rules.
              </p>
            </List.Item>

            <List.Item>
              <p>
                A player may query any sanction issued, through a written
                application (e.g., by email) to the Venue Manager within seven
                days of the sanction being issued.
              </p>
            </List.Item>

            <List.Item>
              <p>
                The Venue Manager will consider the protest and issue a
                determination to the applicant within seven days of the meeting.
                The decision of the Venue Manager will be binding.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 10">
        <Accordion.Control>
          10. ALLOCATION OF COMPETITION POINTS
        </Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="number">
            <List.Item>
              Competition points are allocated on a cumulative basis as follows:
            </List.Item>
            <List listStyleType="disc">
              <List.Item>0 points for a loss</List.Item>
              <List.Item>
                1 point if the team is ready to play with a minimum of six
                players at the appointed match start time.{" "}
              </List.Item>
              <List.Item>
                1 point for each completed set won. A set is deemed to be
                complete if one team has reached thirteen points in the first 4
                sets or eight points in the 5th set and leads by at least two
                points.
              </List.Item>
              <List.Item>
                3 points for the overall winner of the match. (Except
                &lsquo;Draw Win&lsquo;)
              </List.Item>
              <p>
                If at the end of the scheduled time period the set score is
                even, the total number of points won by each team in all sets
                will be calculated to decide a &lsquo;Draw Winner&lsquo; and
                &lsquo;Draw Loser&lsquo;. Points will then be allocated as
                follows: • 2 points Draw Winner. • 1 point Draw Loser. If the
                total points are exactly the same in a count back, the match
                will be deemed a &lsquo;Draw Draw&lsquo; each team will receive
                1.5 points. The maximum number of points per match is 7, not
                including Bonus Points when applicable.
              </p>
            </List>
            <List.Item>
              Competition points may be deducted for such breaches as
              unregistered players, outstanding fees, and forfeits of
              matches/duties or sub-standard duties as per By-law 15.4
            </List.Item>

            <List.Item>
              Bonus Points may be awarded to teams in a variety of ways. This
              may include rewarding teams for full payment of fees, teams in
              full uniform, players attending and completing certain courses,
              i.e. Rules of the Game courses, and also for correct and diligent
              performance of duty - which includes correct completion of
              scoresheet.
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 11">
        <Accordion.Control>11. MATCH FORMAT</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="number">
            <List.Item>
              <p>
                The match format consists of a 10 minute warm up period
                consisting of Five minutes individual player warm up, Four
                minutes spiking at the net (both teams together) and One minute
                serving. During this period the team representatives or captains
                must do the toss, fill in player names and numbers on the
                scoresheet, sign in the appropriate space and submit a rotation
                slip to the scorer.
              </p>
            </List.Item>

            <List.Item>
              <p>
                The match will commence immediately following the warm-up.
                Matches will be best of five sets to a given time limit. If the
                ball is in play at the time limit the rally will be allowed to
                continue. In the event of a replay within the last rally, the
                rally will be replayed.
              </p>
            </List.Item>

            <List.Item>
              <p>
                A team may make up to 12 legal substitutions per set. Any player
                may enter the court up to three times in one set. A player in
                the starting line up is deemed to have made their first entry
                into the set. Up to six players may substitute into any one
                position in the rotation but may not enter into more than one
                position in the rotation in any one set, i.e. a player may not
                change their position within the team rotation (at the start of
                each rally).
              </p>
            </List.Item>

            <List.Item>
              <p>
                The Referee or Venue Manager is to advise both captains when
                there are six minutes remaining in the match, usually done by
                the sounding of a &lsquo;Hooter&lsquo; followed by the
                announcement &lsquo;six minutes&lsquo;
              </p>
            </List.Item>

            <List.Item>
              <p>
                Once the &lsquo;six minutes&lsquo; warning has been announced
                teams have only one minute to call for a &lsquo;Time Out&lsquo;
                or &lsquo;Substitution&lsquo; because inside the last five
                minutes of a match NO stoppages will be permitted. Injured
                players may be substituted according to the normal Rules. If a
                set is completed during this period the teams must change ends
                and the referee will commence the next set with only sufficient
                break for the scorer to complete the scoresheet and record the
                team line-ups.
              </p>
            </List.Item>

            <List.Item>
              <p>
                If a team does request a stoppage during the last five minutes
                after receiving prior notice from the Referee, they may be
                penalised with a time delay under the FIVB rules. If the
                Referee/ Venue Manager fails to advise at the six minutes
                remaining mark, the team may make one substitution only.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 12">
        <Accordion.Control>12. DUTY TEAMS</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="number">
            <List.Item>
              <p>
                All teams in BHV competitions will at times be required to
                perform duty on matches. All teams will, as near as possible, be
                allocated equal amounts of duty responsibilities, especially if
                Duty Bonus points are used in that competition. It is the
                responsibility of each team to provide at least two suitably
                qualified and/or competent Referees.
              </p>
            </List.Item>

            <List.Item>
              <p>
                A duty team consists of a minimum of five people including two
                nominated referees, a scorer and two line judges.
              </p>
            </List.Item>

            <List.Item>
              <p>
                Failure to provide the minimum duty team can result in a
                &lsquo;Duty Forfeit&lsquo; and a sanction as laid down in By-law
                15.4
              </p>
            </List.Item>

            <List.Item>
              <p>
                It is the responsibility of duty teams to set up and dismantle
                court equipment. Duty teams on the first match of each night
                must arrive at least five minutes before the warm-up period to
                set up the court equipment. The duty team for the last game of
                the evening is responsible for packing away the court equipment.
              </p>
            </List.Item>

            <List.Item>
              <p>
                The Duty team needs to nominate the 2 Most Valuable Players
                (MVP&apos;s). Failure to do so can be deemed to be sub-standard
                performance of duty and can be sanctioned according to the
                sanction scale as laid down in By-law 15.4.
              </p>
            </List.Item>

            <List.Item>
              <p>
                The venue manager may also deem a team&apos;s performance of
                duty to be substandard if the team fails to enforce the Rules of
                the Game and the By-laws. A substandard duty can result in a
                sanction as laid down in By-law 15.4.
              </p>
            </List.Item>

            <List.Item>
              <p>
                If a team does not have sufficient qualified referees, it should
                inform the Venue Manager to obtain details of forthcoming
                referees&apos; courses.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 13">
        <Accordion.Control>13. PLAYER ELIGIBILITY FOR FINALS</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="decimal">
            <List.Item>
              <p>
                A player must have played in at least 50 % of the round matches
                with their team to be eligible to play in the Semi-Finals &amp;
                Finals.
              </p>
            </List.Item>
            <List.Item>
              <p>
                It is the responsibility of the referees and/or the duty team to
                ensure that all players listed on the score sheet are present at
                the match. At the completion of the second set the referee
                shall, through instruction to the scorer, delete or cross out
                the name of any player who is not present.
              </p>
            </List.Item>
            <List.Item>
              <p>
                Appeals for exemption from this By-law may be made in writing to
                the Venue Manager.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 14">
        <Accordion.Control>14. FINALS SERIES FORMAT</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="decimal">
            <List.Item>
              <p>
                The teams that finish in the top four places on the competition
                points table after the final round match will be involved in the
                finals series, unless otherwise stated in the draw at the
                commencement of the competition.
              </p>
            </List.Item>
            <List.Item>
              <p>
                The format will be determined by the Venue Manager and explained
                in the competition draw. A Play-Off for third place followed by
                a Grand Final, will determine the final competition results,
                unless otherwise stated at the commencement of the competition.
              </p>
            </List.Item>
            <List.Item>
              <p>
                Trophies, Medals or Certificates may be awarded to the 1st and
                2nd place getters in each grade.
              </p>
            </List.Item>
            <List.Item>
              <p>
                The top two place getters in each grade may also be awarded
                prizes and/or merchandise in recognition of their achievements.
              </p>
            </List.Item>
            <List.Item>
              <p>
                The awards may be presented at completion of each competition or
                at the BHV Annual Presentation Dinner.
              </p>
            </List.Item>
            <List.Item>
              <p>
                If teams are equal on competition points at the end of the round
                matches the semi-final positions will be determined by the
                percentage of sets for and against. If this is equal then the
                percentage of points for and against will determine positions
                for finals.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 15">
        <Accordion.Control>15. FORFEITS AND SANCTIONS SCALE</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="decimal">
            <List.Item>
              <p>
                A team that cannot form a complete team (min 5 players) in
                accordance with By-laws 6 and 7 at the completion of the ten
                minutes warm-up period will immediately forfeit the first set;
                the second set after a further ten minutes and consequently the
                match after a further ten minutes.
              </p>
            </List.Item>
            <List.Item>
              <p>
                A team, which fails to form a complete duty team in accordance
                with By-law 12.2 at the scheduled starting time for the warm-up,
                can be sanctioned. A duty team must have five people in
                attendance at least five minutes before the start of the warm-up
                period for every match to ensure that court equipment is set up,
                in correct order and ready for play.
              </p>
            </List.Item>
            <List.Item>
              <p>
                Teams must have a minimum of Three of their own players to avoid
                an outright forfeit. They can borrow Two players within the
                Rules and possibly a Third player with the permission of the
                opposition’s captain AND the Venue Manager.
              </p>
              <List type="ordered" listStyleType="lower-alpha">
                <List.Item>
                  <p>
                    Teams are welcome to bring new players, or players from
                    other clubs, to fill in for their team if they are short on
                    any given night of competition. However, any new player must
                    fill in a Full Membership or Trial Membership form in
                    accordance with By-law 3.2.1 and 7.8 before they are allowed
                    to play.
                  </p>
                </List.Item>
                <List.Item>
                  <p>
                    If only a Trial Member, they will be able to play for a
                    maximum of two games before needing to become a Full Member.
                  </p>
                </List.Item>
              </List>
            </List.Item>
            <List.Item>
              <Table striped withBorder withColumnBorders>
                <thead>
                  <tr>
                    <th>Breach</th>
                    <th>Bond Reduction</th>
                    <th>Competition Points</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      {row.map((cell, i) => (
                        <td key={i} className={classes.tableCell}>
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 16">
        <Accordion.Control>16. MOST VALUABLE PLAYER AWARDS</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="decimal">
            <List.Item>
              <p>
                This award is designed to reward outstanding performances of
                individuals in all grades of competition. This award can only
                occur if all duty teams in all games complete the scoresheet in
                the appropriate manner.
              </p>
            </List.Item>
            <List.Item>
              <p>
                At the end of each match (excluding Semi-Finals and Finals) the
                Duty team will choose two Most Valuable Players (MVPs), the
                first player is to receive 2 points, the second player is to
                receive 1 point. The scorer is to ensure that this is carried
                out.
              </p>
            </List.Item>
            <List.Item>
              <p>
                Trophies, Medals, Certificates and/or Prizes will be awarded to
                the Most Valuable Players in each of the grades at the
                completion for each competition or at the BHV Annual
                Presentation Dinner.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 17">
        <Accordion.Control>17. NET HEIGHTS</Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="decimal">
            <List.Item>
              <p>
                Duty teams are responsible for maintaining the correct net
                height for each grade.
              </p>
            </List.Item>
            <List.Item>
              <p>
                All ‘Open’ teams in A, B and C Grade will compete at Mixed
                height net (2.35m). At the discretion of the Venue Manager, the
                most competitive A grade competition may be played at Mens
                height net (2.43m) and C grade may be played at Women’s height
                net (2.24m). The BHV Junior Competition will be played at
                heights appropriate for the size and skill of the participants.
                (from 2.10m to 2.35m)
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 18">
        <Accordion.Control>
          18. VOLLEYBALL PENETRATING AIRSPACE OF ADJOINING COURT
        </Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="decimal">
            <List.Item>
              <p>
                A volleyball that is still in play and has left the airspace of
                the court and crossed the sideline of the adjoining court will
                be deemed to be out. The first and/or or second referee must
                blow their whistle at that time and signal that the ball is out.
                A line judge should assist the referees in detecting a ball that
                crosses the sideline of the adjoining court.
              </p>
            </List.Item>
            <List.Item>
              <p>
                In the event that no scheduled match is on an adjoining court
                (court must be empty of players at the time), the Venue Manager
                may instruct referees to allow a ball crossing the sideline of
                the adjoining court to remain in play.
              </p>
            </List.Item>
            <List.Item>
              <p>
                The free space between courts is to remain unobstructed by
                personnel other than referees. That includes Substitute players
                and Coaches.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="By-law Section 19">
        <Accordion.Control>
          19. ON-THE-SPOT-PROTESTS AND ENQUIRIES
        </Accordion.Control>
        <Accordion.Panel>
          <List type="ordered" listStyleType="decimal">
            <List.Item>
              <p>
                On-the-spot-protests or enquiries relating to the interpretation
                or implementation of the Rules of the Game or these By-laws can
                be determined by the Venue Manager, whose decision will be
                final.
              </p>
            </List.Item>
            <List.Item>
              <p>
                It is the responsibility of all team representatives and/or
                captains to ensure that all players in their team are aware of
                the Rules of the Game and these BHV By-laws. No consideration
                will be given to any protest from any team, which does not make
                reference to the relevant section of the Rules of the Game
                and/or these By-laws.
              </p>
            </List.Item>
            <List.Item>
              <p>
                The On-the-spot-protest must be reported at the time of
                occurrence to the 1st referee. The protest must be recorded by
                the team&apos;s captain or coach before the first referee fills
                in, or signs the scoresheet.
              </p>
            </List.Item>
            <List.Item>
              <p>
                The first referee or Venue Manager may also record on the
                scoresheet any infringement of the Rules of the Game or these
                By-laws, which may result in a sanction by BHV.
              </p>
            </List.Item>
            <List.Item>
              <p>
                A formal written protest can also be sent by the game captain or
                coach to the Venue Manager within seven days of the match to be
                at all considered. However, the initial protest must be recorded
                on the scoresheet immediately after the match.
              </p>
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default BylawsAccordion;
