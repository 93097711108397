import { createStyles } from "@mantine/core";

const useStyles = createStyles(() => ({
  wrapper: {
    minHeight: "100vh",
    backgroundImage: `url(https://images.unsplash.com/photo-1614332287897-cdc485fa562d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

function Gallery() {
  const { classes } = useStyles();
  return (
    <div>
      <div className={classes.wrapper}></div>
    </div>
  );
}

export default Gallery;
