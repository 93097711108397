import "react-big-calendar/lib/css/react-big-calendar.css";

import { Container, createStyles, Grid, rem, Title } from "@mantine/core";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { IconBallVolleyball } from "@tabler/icons-react";
import dayjs from "dayjs";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import { Parallax } from "react-scroll-parallax";

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundImage: `radial-gradient(circle, rgba(43,227,140,1) 0%, rgba(0,169,157,1) 66%);`,
  },

  tableWrapper: {
    backgroundColor: theme.colors.gray[1],
    minHeight: "70vh",
    height: "auto",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },

  title: {
    color: `rgba(255, 255, 102)`,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(25),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("md")]: {
      fontSize: rem(18),
    },
  },
}));

function generateEvents() {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const eventsData = [
    {
      day: "Monday",
      start: "19:30",
      end: "21:30",
      title: "Open Social Comp - BHV",
    },
    {
      day: "Tuesday",
      start: "16:00",
      end: "18:00",
      title: "Junior Social Training - BHV",
    },
    {
      day: "Tuesday",
      start: "19:30",
      end: "21:30",
      title: "Open/WOMENS Social Comp - BHV",
    },
    {
      day: "Friday",
      start: "20:30",
      end: "23:00",
      title: "Social Training - North Ryde",
    },
    {
      day: "Saturday",
      start: "12:30",
      end: "14:30",
      title: "Junior Social Training - BHV",
    },
    {
      day: "Saturday",
      start: "14:30",
      end: "16:30",
      title: "Open Social Training - BHV",
    },
    {
      day: "Sunday",
      start: "15:30",
      end: "17:30",
      title: "Junior/Social Training - BHV",
    },
    {
      day: "Sunday",
      start: "17:30",
      end: "19:30",
      title: "Junior Social Comp - BHV",
    },
    {
      day: "Sunday",
      start: "19:30",
      end: "21:30",
      title: "Open Social Comp - BHV",
    },
  ];

  const currentYear = new Date().getFullYear();
  const firstDayOfYear = new Date(currentYear, 0, 1);
  const lastDayOfYear = new Date(currentYear, 11, 31);

  const events = [];

  for (const event of eventsData) {
    const dayIndex = daysOfWeek.indexOf(event.day);

    for (
      let d = new Date(firstDayOfYear);
      d <= lastDayOfYear;
      d.setDate(d.getDate() + 1)
    ) {
      if (d.getDay() === dayIndex) {
        const start = new Date(d);
        start.setHours(parseInt(event.start.split(":")[0], 10));
        start.setMinutes(parseInt(event.start.split(":")[1], 10));

        const end = new Date(start);
        end.setHours(parseInt(event.end.split(":")[0], 10));
        end.setMinutes(parseInt(event.end.split(":")[1], 10));

        events.push({
          title: event.title,
          start: start,
          end: end,
          allDay: false,
        });
      }
    }
  }

  return events;
}

function eventStyleGetter(event: { title: string | string[] }) {
  let backgroundColor = "#3174ad"; // Default color

  if (event.title.includes("North Ryde")) {
    backgroundColor = "#d32f2f"; // Red
  } else if (event.title.includes("Comp")) {
    backgroundColor = "#ffa726"; // Orange
  } else if (event.title.includes("Junior")) {
    backgroundColor = "#1976d2"; // Blue
  } else if (event.title.includes("Open")) {
    backgroundColor = "#4caf50"; // Green
  } else if (event.title.includes("WOMENS")) {
    backgroundColor = "#ab47bc"; // Purple
  } else if (event.title.includes("Training")) {
    backgroundColor = "#009688"; // Teal
  }

  const style = {
    backgroundColor: backgroundColor,
  };

  return {
    style: style,
  };
}

const venues = [
  {
    id: 1,
    name: "Baulkham Hills Sports Centre",
    position: { lat: -33.7518649, lng: 150.9890388 },
  },
  {
    id: 2,
    name: "North Ryde RSL",
    position: { lat: -33.8034158, lng: 151.1365521 },
  },
];

const localizer = dayjsLocalizer(dayjs);

const events = generateEvents();

function Overview() {
  const { classes } = useStyles();
  return (
    <Parallax speed={0}>
      <div className={classes.wrapper}>
        <Container>
          <Title
            order={2}
            ta="center"
            italic
            p={"lg"}
            className={classes.title}
          >
            BHV Venues
          </Title>
          <Grid>
            <Grid.Col span={12}>
              <Title
                order={3}
                ta="center"
                mt="sm"
                italic
                color="white"
                variant="outline"
                mb={"sm"}
                className={classes.title}
              >
                <IconBallVolleyball size={23} /> All activities take place at
                Baulkham Hills High School, except for Friday Social Training
                which is held at North Ryde RSL (Youth Club)
              </Title>
              <LoadScript
                googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
              >
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "400px" }}
                  center={venues[0].position} // Use the position of the first venue
                  zoom={12}
                >
                  {venues.map((venue) => (
                    <Marker key={venue.name} position={venue.position} />
                  ))}
                </GoogleMap>
              </LoadScript>
            </Grid.Col>
            <Grid.Col span={12}>
              <div className={classes.tableWrapper}>
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  defaultView={Views.MONTH}
                  eventPropGetter={eventStyleGetter}
                  style={{ flexGrow: 1, padding: "1rem" }}
                />
              </div>
            </Grid.Col>
          </Grid>
        </Container>
      </div>
    </Parallax>
  );
}

export default Overview;
