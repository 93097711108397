import { Container, Image } from "@mantine/core";
import React from "react";

import Banner from "@/assets/banner.png";
import NavBar from "@/components/navigation/PublicNavBar";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IStandingProps {}

const Standings: React.FunctionComponent<IStandingProps> = () => {
  return (
    <div>
      <NavBar />
      <Container pt={0}>
        <div style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}>
          <Image radius="md" src={Banner} alt="Baulkham Hills Volleyball" />
        </div>
      </Container>
    </div>
  );
};

export default Standings;
