import InfoSection from "@/components/InquirePage/InfoSection";

function Inquries() {
  return (
    <div className="mx-auto">
      <InfoSection />
    </div>
  );
}

export default Inquries;
