import { FooterCentered } from "@/components/LandingPage/Footer";
import Hero from "@/components/LandingPage/HeroHeader";
import Pitch from "@/components/LandingPage/Overview";
import Programs from "@/components/LandingPage/ProgramsCarousel";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IHomePageProps {}

export const FooterLinks = [
  {
    link: "/contact-us",
    label: "Contact Us",
  },
];

const HomePage: React.FunctionComponent<IHomePageProps> = () => {
  return (
    <div className="bg-green-200 max-h-screeen">
      <Hero />
      <Pitch />
    </div>
  );
};

export default HomePage;
