import {
  Container,
  createStyles,
  Grid,
  Image,
  Paper,
  Space,
  Text,
  Title,
} from "@mantine/core";

import bhv_junior_cup_power_rangers from "@/assets/bhv_junior_cup_power_rangers.webp";
import sydney_cup from "@/assets/sydney_cup.webp";
import syndey_cup_winners_2019 from "@/assets/syndey_cup_winners_2019.webp";

const useStyles = createStyles(() => ({
  wrapper: {
    minHeight: "100vh",
    background: "linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)",
  },
}));

function Tournaments(): JSX.Element {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <Container mb={"md"}>
        <Title align="center" my={"md"}>
          Tournaments
        </Title>
        <Grid align="center">
          <Grid.Col span={12} md={6}>
            <Paper p="lg">
              <Title order={3} align="center" mb={"sm"}>
                BHV One-Day Tournaments!
              </Title>
              <Text mb={"md"}>
                For those looking for external competitive action, BHV runs
                graded one day tournaments.
              </Text>
              <Text mb={"md"}>
                Teams and players that want to play at the next level than
                Social Competition will find the tournaments below are exactly
                what they are looking for!
              </Text>
              <Text>
                They are held over one day , and usually on Saturday and they
                allow for teams to play at least 3-4 games against other teams
                during the tournament.
              </Text>
              <Space h={20} />
              <Title order={3} align="center" mb={"sm"}>
                BHV Sydney Cups
              </Title>
              <Text mb={"md"}>
                The BHV Sydney Cups are held at various Sydney venues about 5 or
                6 times a year.
              </Text>
              <Text>
                They are aimed at Divison 1, 2, 3 & 4 Men and Womens Representative
                teams or individuals who team up and can handle a higher level
                of play.
              </Text>

              <Space h={20} />
              <Title order={3} align="center" my={"md"}>
                BHV Mixed Nuts Tournaments
              </Title>
              <Text mb={"md"}>
                Mixed NUTS Tournaments have been at BHV since the club was
                established and are some of the most popular events run at BHV!!
              </Text>
              <Text mb={"md"}>
                Two Mixed NUTS Tornaments are usually held every year to ensure
                everyone gets the chance to participate. Strict mixed rules
                apply- 3 guys and 3 girls on the court and 3 consecutive touches
                cannot be carried out by one sex!
              </Text>

              <Text mb={"md"}>
                Teams are graded and guys playing as &apos;Special Girls&apos;
                wear an identifying Bra and girls playing as &apos;Special
                Guys&apos; wear Boxers!! So, when Music and Dress Up come
                together at Mixed NUTS you can be sure it will be an
                unforgetable experience!
              </Text>
              <Space h={20} />
              <Title order={3} align="center" my={"md"}>
                BHV Junior Cups
              </Title>
              <Text mb={"md"}>
                The BHV JUNIOR Cups are held at various Sydney venues when
                possible to cater for the Junior teams in the U/15, U/17, U/19
                age groups for Boys & Girls.
              </Text>
            </Paper>
          </Grid.Col>
          <Grid.Col span={12} md={6} className="max-w-[420px]">
            <Image
              src={syndey_cup_winners_2019}
              alt="Sydney Cup Winners 2019"
              width="100%"
              height="auto"
              my={"sm"}
            />
            <Image
              src={sydney_cup}
              alt="Sydney Cup"
              width="100%"
              height="auto"
              my={"sm"}
            />
            <Image
              src={bhv_junior_cup_power_rangers}
              alt="Sydney Cup"
              width="100%"
              height="auto"
              my={"sm"}
            />
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
}

export default Tournaments;
