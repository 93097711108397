/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query InquiriesSlice {\n    inquiriesSlice {\n      id\n      name\n      email\n      phone\n      subject\n      message\n      status\n      createdAt\n      updatedAt\n    }\n  }\n": types.InquiriesSliceDocument,
    "\n  query TransactionsSlice {\n    transactionsSlice {\n      id\n      type\n      status\n      amount\n      member {\n        firstName\n        lastName\n      }\n      createdAt\n    }\n  }\n": types.TransactionsSliceDocument,
    "\n  mutation Mutation(\n    $updateTransactionId: Int!\n    $input: UpdateTransactionsInput!\n  ) {\n    updateTransaction(id: $updateTransactionId, input: $input) {\n      id\n      type\n      status\n      notes\n      updatedAt\n      createdAt\n      amount\n    }\n  }\n": types.MutationDocument,
    "\n  query MembersLineChart {\n    members {\n      id\n      firstName\n      lastName\n      membership {\n        memberType\n      }\n      joinDate\n      createdAt\n    }\n  }\n": types.MembersLineChartDocument,
    "\n  mutation CreateInquiry($input: CreateInquiriesInput!) {\n    createInquiry(input: $input) {\n      id\n      name\n      email\n      phone\n      subject\n      message\n    }\n  }\n": types.CreateInquiryDocument,
    "\n  query Inquiry($inquiryId: Int!) {\n    inquiry(id: $inquiryId) {\n      id\n      name\n      email\n      phone\n      subject\n      message\n      status\n      createdAt\n      updatedAt\n    }\n  }\n": types.InquiryDocument,
    "\n  mutation UpdateInquiry(\n    $updateInquiryId: Int!\n    $input: UpdateInquiriesInput!\n  ) {\n    updateInquiry(id: $updateInquiryId, input: $input) {\n      status\n    }\n  }\n": types.UpdateInquiryDocument,
    "\n  query Team($teamId: Int!) {\n    team(id: $teamId) {\n      id\n      teamName\n      createdAt\n      updatedAt\n      teamMembers {\n        id\n      }\n    }\n  }\n": types.TeamDocument,
    "\n  query TeamMembersWithImage {\n    teamMembers {\n      id\n      role\n      position\n      member {\n        firstName\n        lastName\n        image\n      }\n      team {\n        teamName\n      }\n    }\n  }\n": types.TeamMembersWithImageDocument,
    "\n  mutation UpdateTeam(\n    $updateTeamId: Int!\n    $input: UpdateTeamInput!\n    $file: Upload\n  ) {\n    updateTeam(id: $updateTeamId, input: $input, file: $file) {\n      teamName\n      id\n      createdAt\n      updatedAt\n      teamMembers {\n        id\n        role\n        position\n        member {\n          id\n          firstName\n          lastName\n          image\n        }\n      }\n    }\n  }\n": types.UpdateTeamDocument,
    "\n  mutation DeleteTeam($deleteTeamId: Int!) {\n    deleteTeam(id: $deleteTeamId)\n  }\n": types.DeleteTeamDocument,
    "\n  query TeamMember($teamMemberId: Int!) {\n    teamMember(id: $teamMemberId) {\n      id\n      role\n      position\n      createdAt\n      updatedAt\n      member {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n      team {\n        id\n        teamName\n      }\n    }\n  }\n": types.TeamMemberDocument,
    "\n  query TeamsWithImage {\n    teams {\n      id\n      teamName\n      createdAt\n      updatedAt\n      image\n    }\n  }\n": types.TeamsWithImageDocument,
    "\n  mutation UpdateTeamMember(\n    $updateTeamMemberId: Int!\n    $input: UpdateTeamMemberInput!\n  ) {\n    updateTeamMember(id: $updateTeamMemberId, input: $input) {\n      id\n      role\n      position\n      createdAt\n      updatedAt\n    }\n  }\n": types.UpdateTeamMemberDocument,
    "\n  mutation DeleteTeamMember($deleteTeamMemberId: Int!) {\n    deleteTeamMember(id: $deleteTeamMemberId)\n  }\n": types.DeleteTeamMemberDocument,
    "\n  mutation CreateEvent($input: CreateEventInput!) {\n    createEvent(input: $input) {\n      id\n      name\n      description\n      notes\n      status\n      startTime\n      endTime\n      location\n      createdAt\n      updatedAt\n    }\n  }\n": types.CreateEventDocument,
    "\n  query EventTypes {\n    eventTypes {\n      id\n      eventType\n      description\n      ageRequirement\n      createdAt\n      updatedAt\n    }\n  }\n": types.EventTypesDocument,
    "\n  query MembersWithImage {\n    members {\n      id\n      firstName\n      lastName\n      email\n      phone\n      image\n    }\n  }\n": types.MembersWithImageDocument,
    "\n  query MemberSinglePaymentDetails($memberId: Int) {\n    member(memberID: $memberId) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      image\n      transactions {\n        id\n        type\n        status\n        amount\n      }\n    }\n  }\n": types.MemberSinglePaymentDetailsDocument,
    "\n  mutation CreateTransaction($input: CreateTransactionsInput!) {\n    createTransaction(input: $input) {\n      id\n      type\n      status\n      amount\n      notes\n      date\n      createdAt\n      updatedAt\n    }\n  }\n": types.CreateTransactionDocument,
    "\n  mutation UpdateAllTransactionsByIds(\n    $ids: [Int!]!\n    $input: UpdateTransactionsInput!\n  ) {\n    updateAllTransactionsByIds(ids: $ids, input: $input) {\n      id\n      type\n      status\n      amount\n      notes\n      date\n      createdAt\n      updatedAt\n    }\n  }\n": types.UpdateAllTransactionsByIdsDocument,
    "\n  mutation CreateEventAttendance($input: CreateEventAttendanceInput!) {\n    createEventAttendance(input: $input) {\n      id\n      createdAt\n      updatedAt\n    }\n  }\n": types.CreateEventAttendanceDocument,
    "\n  mutation CreateEventType($input: CreateEventTypeInput!) {\n    createEventType(input: $input) {\n      id\n      eventType\n      description\n      ageRequirement\n      createdAt\n      updatedAt\n    }\n  }\n": types.CreateEventTypeDocument,
    "\n  query Event($eventId: Int!) {\n    event(id: $eventId) {\n      id\n      name\n      description\n      notes\n      status\n      startTime\n      endTime\n      location\n      createdAt\n      updatedAt\n      eventType {\n        id\n        eventType\n      }\n    }\n  }\n": types.EventDocument,
    "\n  mutation UpdateEvent($updateEventId: Int!, $input: UpdateEventInput!) {\n    updateEvent(id: $updateEventId, input: $input) {\n      id\n      name\n      description\n      notes\n      status\n      startTime\n      endTime\n      location\n      createdAt\n      updatedAt\n      eventType {\n        eventType\n      }\n    }\n  }\n": types.UpdateEventDocument,
    "\n  mutation DeleteEvent($deleteEventId: Int!) {\n    deleteEvent(id: $deleteEventId)\n  }\n": types.DeleteEventDocument,
    "\n  query EventType($eventTypeId: Int!) {\n    eventType(id: $eventTypeId) {\n      id\n      eventType\n      description\n      createdAt\n      updatedAt\n    }\n  }\n": types.EventTypeDocument,
    "\n  mutation UpdateEventType(\n    $updateEventTypeId: Int!\n    $input: UpdateEventTypeInput!\n  ) {\n    updateEventType(id: $updateEventTypeId, input: $input) {\n      id\n      eventType\n      description\n      createdAt\n      updatedAt\n    }\n  }\n": types.UpdateEventTypeDocument,
    "\n  mutation DeleteEventType($deleteEventTypeId: Int!) {\n    deleteEventType(id: $deleteEventTypeId)\n  }\n": types.DeleteEventTypeDocument,
    "\n  query TeamsWithParentID($teamsWithParentIdId: Int!) {\n    teamsWithParentID(id: $teamsWithParentIdId) {\n      id\n      teamName\n      createdAt\n      updatedAt\n      teamMembers {\n        member {\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n": types.TeamsWithParentIdDocument,
    "\n  query MatchesSlice {\n    matchesSlice {\n      id\n      homeTeam {\n        teamName\n      }\n      awayTeam {\n        teamName\n      }\n      matchDate\n      matchTime\n    }\n  }\n": types.MatchesSliceDocument,
    "\n  mutation CreateScore($input: CreateScoreInput!) {\n    createScore(input: $input) {\n      id\n      set\n      points\n      matchDate\n      outcome\n      createdAt\n      updatedAt\n    }\n  }\n": types.CreateScoreDocument,
    "\n  query MatchResult($matchResultId: Int!) {\n    matchResult(id: $matchResultId) {\n      id\n      homeTeamScore\n      awayTeamScore\n      match {\n        matchDate\n        matchTime\n        homeTeam {\n          teamName\n          image\n        }\n        awayTeam {\n          teamName\n          image\n        }\n        onDuty {\n          teamName\n        }\n      }\n    }\n  }\n": types.MatchResultDocument,
    "\n  query Teams {\n    teams {\n      id\n      teamName\n      createdAt\n      updatedAt\n    }\n  }\n": types.TeamsDocument,
    "\n  query Match($matchId: Int!) {\n    match(id: $matchId) {\n      id\n      matchDate\n      matchTime\n      division\n      matchType\n      matchLocation\n      createdAt\n      updatedAt\n      homeTeam {\n        id\n        teamName\n      }\n      awayTeam {\n        id\n        teamName\n      }\n      onDuty {\n        id\n        teamName\n      }\n    }\n  }\n": types.MatchDocument,
    "\n  mutation UpdateMatch($updateMatchId: Int!, $input: UpdateMatchesInput!) {\n    updateMatch(id: $updateMatchId, input: $input) {\n      matchDate\n      matchTime\n      division\n      matchType\n      matchLocation\n      homeTeam {\n        id\n      }\n      awayTeam {\n        id\n      }\n      onDuty {\n        id\n      }\n    }\n  }\n": types.UpdateMatchDocument,
    "\n  mutation DeleteMatch($deleteMatchId: Int!) {\n    deleteMatch(id: $deleteMatchId)\n  }\n": types.DeleteMatchDocument,
    "\n  mutation DeleteMatchResult($deleteMatchResultId: Int!) {\n    deleteMatchResult(id: $deleteMatchResultId)\n  }\n": types.DeleteMatchResultDocument,
    "\n  mutation UpdateAddress($input: UpdateAddressInput!, $memberId: Int!) {\n    updateAddress(input: $input, memberId: $memberId) {\n      id\n      street\n      houseNumber\n      suburb\n      postalCode\n      city\n      createdAt\n      updatedAt\n    }\n  }\n": types.UpdateAddressDocument,
    "\n  query MemberAddress($memberId: Int!) {\n    memberAddress(memberID: $memberId) {\n      id\n      street\n      houseNumber\n      suburb\n      postalCode\n      city\n      createdAt\n      updatedAt\n    }\n  }\n": types.MemberAddressDocument,
    "\n  query MemberAttendance($memberId: Int!) {\n    memberAttendance(memberID: $memberId) {\n      id\n      createdAt\n      updatedAt\n      event {\n        id\n        name\n        startTime\n        endTime\n        eventType {\n          eventType\n        }\n      }\n    }\n  }\n": types.MemberAttendanceDocument,
    "\n  mutation UpdateMember(\n    $input: UpdateMemberInput!\n    $memberId: Int\n    $file: Upload\n  ) {\n    updateMember(input: $input, memberId: $memberId, file: $file) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      dob\n      gender\n      occupation\n      canBeReferredToBhv\n      status\n      joinDate\n      createdAt\n      updatedAt\n      notes\n    }\n  }\n": types.UpdateMemberDocument,
    "\n  query MemberWithImage($memberId: Int) {\n    member(memberID: $memberId) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      dob\n      gender\n      occupation\n      canBeReferredToBhv\n      status\n      joinDate\n      createdAt\n      updatedAt\n      notes\n      image\n      membership {\n        id\n        memberType\n        price\n        description\n        maxSessions\n        maxGames\n        maxTrainings\n        ageRequirement\n        hasInjuryInsuranceCoverage\n        validMonths\n        createdAt\n        updatedAt\n      }\n    }\n  }\n": types.MemberWithImageDocument,
    "\n  query Memberships {\n    memberships {\n      id\n      memberType\n      price\n      description\n      maxSessions\n      maxGames\n      maxTrainings\n      ageRequirement\n      hasInjuryInsuranceCoverage\n      validMonths\n      createdAt\n      updatedAt\n    }\n  }\n": types.MembershipsDocument,
    "\n  mutation DeleteMember($memberId: Int) {\n    deleteMember(memberId: $memberId)\n  }\n": types.DeleteMemberDocument,
    "\n  mutation CreateMemberWithImage($input: CreateMemberInput!, $file: Upload) {\n    createMember(input: $input, file: $file) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      dob\n      gender\n      occupation\n      canBeReferredToBhv\n      status\n      joinDate\n      createdAt\n      updatedAt\n      notes\n      address {\n        id\n        houseNumber\n        suburb\n        postalCode\n        city\n        createdAt\n        updatedAt\n      }\n    }\n  }\n": types.CreateMemberWithImageDocument,
    "\n  query Members {\n    members {\n      id\n      firstName\n      lastName\n      email\n      phone\n    }\n  }\n": types.MembersDocument,
    "\n  query MemberTransactions($memberId: Int!) {\n    memberTransactions(memberID: $memberId) {\n      amount\n      createdAt\n      date\n      id\n      notes\n      status\n      type\n      updatedAt\n    }\n  }\n": types.MemberTransactionsDocument,
    "\n  mutation CreateMembership($input: CreateMembershipInput!) {\n    createMembership(input: $input) {\n      id\n      memberType\n      price\n      description\n      maxSessions\n      maxGames\n      maxTrainings\n      ageRequirement\n      hasInjuryInsuranceCoverage\n      validMonths\n      createdAt\n      updatedAt\n    }\n  }\n": types.CreateMembershipDocument,
    "\n  query Membership($membershipId: Int!) {\n    membership(id: $membershipId) {\n      id\n      memberType\n      price\n      description\n      maxSessions\n      maxGames\n      maxTrainings\n      ageRequirement\n      hasInjuryInsuranceCoverage\n      validMonths\n      createdAt\n      updatedAt\n    }\n  }\n": types.MembershipDocument,
    "\n  mutation UpdateMembership(\n    $input: UpdateMembershipInput!\n    $updateMembershipId: Int\n  ) {\n    updateMembership(input: $input, id: $updateMembershipId) {\n      id\n      memberType\n      price\n      description\n      maxSessions\n      maxGames\n      maxTrainings\n      ageRequirement\n      hasInjuryInsuranceCoverage\n      validMonths\n      createdAt\n      updatedAt\n    }\n  }\n": types.UpdateMembershipDocument,
    "\n  mutation DeleteMembership($deleteMembershipId: Int!) {\n    deleteMembership(id: $deleteMembershipId)\n  }\n": types.DeleteMembershipDocument,
    "\n  mutation CreateMemberWithImageModal(\n    $input: CreateMemberInput!\n    $file: Upload\n  ) {\n    createMember(input: $input, file: $file) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      dob\n      gender\n      occupation\n      canBeReferredToBhv\n      status\n      joinDate\n      createdAt\n      updatedAt\n      notes\n      address {\n        id\n        houseNumber\n        suburb\n        postalCode\n        city\n        createdAt\n        updatedAt\n      }\n    }\n  }\n": types.CreateMemberWithImageModalDocument,
    "\n  mutation CreateMatch($input: CreateMatchesInput!) {\n    createMatch(input: $input) {\n      id\n    }\n  }\n": types.CreateMatchDocument,
    "\n  mutation CreateTeamMember($input: CreateTeamMemberInput!) {\n    createTeamMember(input: $input) {\n      id\n      role\n      position\n      createdAt\n      updatedAt\n    }\n  }\n": types.CreateTeamMemberDocument,
    "\n  mutation CreateTeam($input: CreateTeamInput!, $file: Upload) {\n    createTeam(input: $input, file: $file) {\n      id\n      teamName\n      createdAt\n      updatedAt\n    }\n  }\n": types.CreateTeamDocument,
    "\n  query Transaction($transactionId: Int!) {\n    transaction(id: $transactionId) {\n      id\n      type\n      status\n      amount\n      notes\n      date\n      createdAt\n      updatedAt\n      member {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n    }\n  }\n": types.TransactionDocument,
    "\n  mutation DeleteTransaction($deleteTransactionId: Int!) {\n    deleteTransaction(id: $deleteTransactionId)\n  }\n": types.DeleteTransactionDocument,
    "\n  mutation CreateUser($input: CreateUsersInput!) {\n    createUser(input: $input) {\n      id\n    }\n  }\n": types.CreateUserDocument,
    "\n  query User($userId: Int!) {\n    user(id: $userId) {\n      id\n      name\n      username\n      email\n      role\n      status\n      lastLogin\n      createdAt\n      updatedAt\n      member {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n    }\n  }\n": types.UserDocument,
    "\n  mutation UpdateUser($input: UpdateUsersInput!, $updateUserId: Int) {\n    updateUser(input: $input, id: $updateUserId) {\n      id\n      name\n      username\n      email\n      role\n      status\n      lastLogin\n      createdAt\n      updatedAt\n      member {\n        id\n      }\n    }\n  }\n": types.UpdateUserDocument,
    "\n  mutation UpdateUserPassword(\n    $input: UpdateUsersInput!\n    $updateUserId: Int\n    $oldPassword: String\n  ) {\n    updateUser(input: $input, id: $updateUserId, oldPassword: $oldPassword) {\n      id\n    }\n  }\n": types.UpdateUserPasswordDocument,
    "\n  mutation Login($input: LoginInput!) {\n    login(input: $input) {\n      id\n      name\n      role\n      status\n      token\n    }\n  }\n": types.LoginDocument,
    "\n  mutation CreateUserPublicSignUp($input: CreateUsersInput!) {\n    createUser(input: $input) {\n      id\n      name\n      username\n      email\n      member {\n        id\n      }\n    }\n  }\n": types.CreateUserPublicSignUpDocument,
    "\n    query Events {\n      events {\n        id\n        name\n        description\n        notes\n        status\n        startTime\n        endTime\n        location\n        createdAt\n        updatedAt\n        eventType {\n          eventType\n        }\n      }\n    }\n  ": types.EventsDocument,
    "\n  query Query {\n    matchesSlice {\n      id\n      matchDate\n      matchTime\n      division\n      matchType\n      matchLocation\n      createdAt\n      updatedAt\n      homeTeam {\n        teamName\n      }\n      awayTeam {\n        teamName\n      }\n      onDuty {\n        teamName\n      }\n    }\n  }\n": types.QueryDocument,
    "\n  query MatchScores($matchId: Int!) {\n    matchScores(matchId: $matchId) {\n      id\n      set\n      points\n      matchDate\n      outcome\n      team {\n        teamName\n      }\n    }\n  }\n": types.MatchScoresDocument,
    "\n  mutation DeleteScore($deleteScoreId: Int!) {\n    deleteScore(id: $deleteScoreId)\n  }\n": types.DeleteScoreDocument,
    "\n  query TeamMembers {\n    teamMembers {\n      id\n      role\n      position\n      member {\n        firstName\n        lastName\n      }\n      team {\n        teamName\n      }\n    }\n  }\n": types.TeamMembersDocument,
    "\n    query EventTypes {\n      eventTypes {\n        id\n        eventType\n        description\n        ageRequirement\n        createdAt\n        updatedAt\n      }\n    }\n  ": types.EventTypesDocument,
    "\n  query TableMembers {\n    members {\n      id\n      firstName\n      lastName\n      email\n      phone\n      membership {\n        memberType\n      }\n    }\n  }\n": types.TableMembersDocument,
    "\n    query Memberships {\n      memberships {\n        id\n        memberType\n        price\n        description\n        maxSessions\n        maxGames\n        maxTrainings\n        ageRequirement\n        hasInjuryInsuranceCoverage\n        validMonths\n        createdAt\n        updatedAt\n      }\n    }\n  ": types.MembershipsDocument,
    "\n    query UsersSlice {\n      usersSlice {\n        id\n        name\n        username\n        email\n        role\n        status\n        lastLogin\n        createdAt\n        updatedAt\n      }\n    }\n  ": types.UsersSliceDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query InquiriesSlice {\n    inquiriesSlice {\n      id\n      name\n      email\n      phone\n      subject\n      message\n      status\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query InquiriesSlice {\n    inquiriesSlice {\n      id\n      name\n      email\n      phone\n      subject\n      message\n      status\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TransactionsSlice {\n    transactionsSlice {\n      id\n      type\n      status\n      amount\n      member {\n        firstName\n        lastName\n      }\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query TransactionsSlice {\n    transactionsSlice {\n      id\n      type\n      status\n      amount\n      member {\n        firstName\n        lastName\n      }\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Mutation(\n    $updateTransactionId: Int!\n    $input: UpdateTransactionsInput!\n  ) {\n    updateTransaction(id: $updateTransactionId, input: $input) {\n      id\n      type\n      status\n      notes\n      updatedAt\n      createdAt\n      amount\n    }\n  }\n"): (typeof documents)["\n  mutation Mutation(\n    $updateTransactionId: Int!\n    $input: UpdateTransactionsInput!\n  ) {\n    updateTransaction(id: $updateTransactionId, input: $input) {\n      id\n      type\n      status\n      notes\n      updatedAt\n      createdAt\n      amount\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MembersLineChart {\n    members {\n      id\n      firstName\n      lastName\n      membership {\n        memberType\n      }\n      joinDate\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query MembersLineChart {\n    members {\n      id\n      firstName\n      lastName\n      membership {\n        memberType\n      }\n      joinDate\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateInquiry($input: CreateInquiriesInput!) {\n    createInquiry(input: $input) {\n      id\n      name\n      email\n      phone\n      subject\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation CreateInquiry($input: CreateInquiriesInput!) {\n    createInquiry(input: $input) {\n      id\n      name\n      email\n      phone\n      subject\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Inquiry($inquiryId: Int!) {\n    inquiry(id: $inquiryId) {\n      id\n      name\n      email\n      phone\n      subject\n      message\n      status\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query Inquiry($inquiryId: Int!) {\n    inquiry(id: $inquiryId) {\n      id\n      name\n      email\n      phone\n      subject\n      message\n      status\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateInquiry(\n    $updateInquiryId: Int!\n    $input: UpdateInquiriesInput!\n  ) {\n    updateInquiry(id: $updateInquiryId, input: $input) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateInquiry(\n    $updateInquiryId: Int!\n    $input: UpdateInquiriesInput!\n  ) {\n    updateInquiry(id: $updateInquiryId, input: $input) {\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Team($teamId: Int!) {\n    team(id: $teamId) {\n      id\n      teamName\n      createdAt\n      updatedAt\n      teamMembers {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query Team($teamId: Int!) {\n    team(id: $teamId) {\n      id\n      teamName\n      createdAt\n      updatedAt\n      teamMembers {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TeamMembersWithImage {\n    teamMembers {\n      id\n      role\n      position\n      member {\n        firstName\n        lastName\n        image\n      }\n      team {\n        teamName\n      }\n    }\n  }\n"): (typeof documents)["\n  query TeamMembersWithImage {\n    teamMembers {\n      id\n      role\n      position\n      member {\n        firstName\n        lastName\n        image\n      }\n      team {\n        teamName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateTeam(\n    $updateTeamId: Int!\n    $input: UpdateTeamInput!\n    $file: Upload\n  ) {\n    updateTeam(id: $updateTeamId, input: $input, file: $file) {\n      teamName\n      id\n      createdAt\n      updatedAt\n      teamMembers {\n        id\n        role\n        position\n        member {\n          id\n          firstName\n          lastName\n          image\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTeam(\n    $updateTeamId: Int!\n    $input: UpdateTeamInput!\n    $file: Upload\n  ) {\n    updateTeam(id: $updateTeamId, input: $input, file: $file) {\n      teamName\n      id\n      createdAt\n      updatedAt\n      teamMembers {\n        id\n        role\n        position\n        member {\n          id\n          firstName\n          lastName\n          image\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteTeam($deleteTeamId: Int!) {\n    deleteTeam(id: $deleteTeamId)\n  }\n"): (typeof documents)["\n  mutation DeleteTeam($deleteTeamId: Int!) {\n    deleteTeam(id: $deleteTeamId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TeamMember($teamMemberId: Int!) {\n    teamMember(id: $teamMemberId) {\n      id\n      role\n      position\n      createdAt\n      updatedAt\n      member {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n      team {\n        id\n        teamName\n      }\n    }\n  }\n"): (typeof documents)["\n  query TeamMember($teamMemberId: Int!) {\n    teamMember(id: $teamMemberId) {\n      id\n      role\n      position\n      createdAt\n      updatedAt\n      member {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n      team {\n        id\n        teamName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TeamsWithImage {\n    teams {\n      id\n      teamName\n      createdAt\n      updatedAt\n      image\n    }\n  }\n"): (typeof documents)["\n  query TeamsWithImage {\n    teams {\n      id\n      teamName\n      createdAt\n      updatedAt\n      image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateTeamMember(\n    $updateTeamMemberId: Int!\n    $input: UpdateTeamMemberInput!\n  ) {\n    updateTeamMember(id: $updateTeamMemberId, input: $input) {\n      id\n      role\n      position\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTeamMember(\n    $updateTeamMemberId: Int!\n    $input: UpdateTeamMemberInput!\n  ) {\n    updateTeamMember(id: $updateTeamMemberId, input: $input) {\n      id\n      role\n      position\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteTeamMember($deleteTeamMemberId: Int!) {\n    deleteTeamMember(id: $deleteTeamMemberId)\n  }\n"): (typeof documents)["\n  mutation DeleteTeamMember($deleteTeamMemberId: Int!) {\n    deleteTeamMember(id: $deleteTeamMemberId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateEvent($input: CreateEventInput!) {\n    createEvent(input: $input) {\n      id\n      name\n      description\n      notes\n      status\n      startTime\n      endTime\n      location\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation CreateEvent($input: CreateEventInput!) {\n    createEvent(input: $input) {\n      id\n      name\n      description\n      notes\n      status\n      startTime\n      endTime\n      location\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EventTypes {\n    eventTypes {\n      id\n      eventType\n      description\n      ageRequirement\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query EventTypes {\n    eventTypes {\n      id\n      eventType\n      description\n      ageRequirement\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MembersWithImage {\n    members {\n      id\n      firstName\n      lastName\n      email\n      phone\n      image\n    }\n  }\n"): (typeof documents)["\n  query MembersWithImage {\n    members {\n      id\n      firstName\n      lastName\n      email\n      phone\n      image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MemberSinglePaymentDetails($memberId: Int) {\n    member(memberID: $memberId) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      image\n      transactions {\n        id\n        type\n        status\n        amount\n      }\n    }\n  }\n"): (typeof documents)["\n  query MemberSinglePaymentDetails($memberId: Int) {\n    member(memberID: $memberId) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      image\n      transactions {\n        id\n        type\n        status\n        amount\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateTransaction($input: CreateTransactionsInput!) {\n    createTransaction(input: $input) {\n      id\n      type\n      status\n      amount\n      notes\n      date\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTransaction($input: CreateTransactionsInput!) {\n    createTransaction(input: $input) {\n      id\n      type\n      status\n      amount\n      notes\n      date\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateAllTransactionsByIds(\n    $ids: [Int!]!\n    $input: UpdateTransactionsInput!\n  ) {\n    updateAllTransactionsByIds(ids: $ids, input: $input) {\n      id\n      type\n      status\n      amount\n      notes\n      date\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateAllTransactionsByIds(\n    $ids: [Int!]!\n    $input: UpdateTransactionsInput!\n  ) {\n    updateAllTransactionsByIds(ids: $ids, input: $input) {\n      id\n      type\n      status\n      amount\n      notes\n      date\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateEventAttendance($input: CreateEventAttendanceInput!) {\n    createEventAttendance(input: $input) {\n      id\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation CreateEventAttendance($input: CreateEventAttendanceInput!) {\n    createEventAttendance(input: $input) {\n      id\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateEventType($input: CreateEventTypeInput!) {\n    createEventType(input: $input) {\n      id\n      eventType\n      description\n      ageRequirement\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation CreateEventType($input: CreateEventTypeInput!) {\n    createEventType(input: $input) {\n      id\n      eventType\n      description\n      ageRequirement\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Event($eventId: Int!) {\n    event(id: $eventId) {\n      id\n      name\n      description\n      notes\n      status\n      startTime\n      endTime\n      location\n      createdAt\n      updatedAt\n      eventType {\n        id\n        eventType\n      }\n    }\n  }\n"): (typeof documents)["\n  query Event($eventId: Int!) {\n    event(id: $eventId) {\n      id\n      name\n      description\n      notes\n      status\n      startTime\n      endTime\n      location\n      createdAt\n      updatedAt\n      eventType {\n        id\n        eventType\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateEvent($updateEventId: Int!, $input: UpdateEventInput!) {\n    updateEvent(id: $updateEventId, input: $input) {\n      id\n      name\n      description\n      notes\n      status\n      startTime\n      endTime\n      location\n      createdAt\n      updatedAt\n      eventType {\n        eventType\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateEvent($updateEventId: Int!, $input: UpdateEventInput!) {\n    updateEvent(id: $updateEventId, input: $input) {\n      id\n      name\n      description\n      notes\n      status\n      startTime\n      endTime\n      location\n      createdAt\n      updatedAt\n      eventType {\n        eventType\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteEvent($deleteEventId: Int!) {\n    deleteEvent(id: $deleteEventId)\n  }\n"): (typeof documents)["\n  mutation DeleteEvent($deleteEventId: Int!) {\n    deleteEvent(id: $deleteEventId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EventType($eventTypeId: Int!) {\n    eventType(id: $eventTypeId) {\n      id\n      eventType\n      description\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query EventType($eventTypeId: Int!) {\n    eventType(id: $eventTypeId) {\n      id\n      eventType\n      description\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateEventType(\n    $updateEventTypeId: Int!\n    $input: UpdateEventTypeInput!\n  ) {\n    updateEventType(id: $updateEventTypeId, input: $input) {\n      id\n      eventType\n      description\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateEventType(\n    $updateEventTypeId: Int!\n    $input: UpdateEventTypeInput!\n  ) {\n    updateEventType(id: $updateEventTypeId, input: $input) {\n      id\n      eventType\n      description\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteEventType($deleteEventTypeId: Int!) {\n    deleteEventType(id: $deleteEventTypeId)\n  }\n"): (typeof documents)["\n  mutation DeleteEventType($deleteEventTypeId: Int!) {\n    deleteEventType(id: $deleteEventTypeId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TeamsWithParentID($teamsWithParentIdId: Int!) {\n    teamsWithParentID(id: $teamsWithParentIdId) {\n      id\n      teamName\n      createdAt\n      updatedAt\n      teamMembers {\n        member {\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query TeamsWithParentID($teamsWithParentIdId: Int!) {\n    teamsWithParentID(id: $teamsWithParentIdId) {\n      id\n      teamName\n      createdAt\n      updatedAt\n      teamMembers {\n        member {\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MatchesSlice {\n    matchesSlice {\n      id\n      homeTeam {\n        teamName\n      }\n      awayTeam {\n        teamName\n      }\n      matchDate\n      matchTime\n    }\n  }\n"): (typeof documents)["\n  query MatchesSlice {\n    matchesSlice {\n      id\n      homeTeam {\n        teamName\n      }\n      awayTeam {\n        teamName\n      }\n      matchDate\n      matchTime\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateScore($input: CreateScoreInput!) {\n    createScore(input: $input) {\n      id\n      set\n      points\n      matchDate\n      outcome\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation CreateScore($input: CreateScoreInput!) {\n    createScore(input: $input) {\n      id\n      set\n      points\n      matchDate\n      outcome\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MatchResult($matchResultId: Int!) {\n    matchResult(id: $matchResultId) {\n      id\n      homeTeamScore\n      awayTeamScore\n      match {\n        matchDate\n        matchTime\n        homeTeam {\n          teamName\n          image\n        }\n        awayTeam {\n          teamName\n          image\n        }\n        onDuty {\n          teamName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query MatchResult($matchResultId: Int!) {\n    matchResult(id: $matchResultId) {\n      id\n      homeTeamScore\n      awayTeamScore\n      match {\n        matchDate\n        matchTime\n        homeTeam {\n          teamName\n          image\n        }\n        awayTeam {\n          teamName\n          image\n        }\n        onDuty {\n          teamName\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Teams {\n    teams {\n      id\n      teamName\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query Teams {\n    teams {\n      id\n      teamName\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Match($matchId: Int!) {\n    match(id: $matchId) {\n      id\n      matchDate\n      matchTime\n      division\n      matchType\n      matchLocation\n      createdAt\n      updatedAt\n      homeTeam {\n        id\n        teamName\n      }\n      awayTeam {\n        id\n        teamName\n      }\n      onDuty {\n        id\n        teamName\n      }\n    }\n  }\n"): (typeof documents)["\n  query Match($matchId: Int!) {\n    match(id: $matchId) {\n      id\n      matchDate\n      matchTime\n      division\n      matchType\n      matchLocation\n      createdAt\n      updatedAt\n      homeTeam {\n        id\n        teamName\n      }\n      awayTeam {\n        id\n        teamName\n      }\n      onDuty {\n        id\n        teamName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateMatch($updateMatchId: Int!, $input: UpdateMatchesInput!) {\n    updateMatch(id: $updateMatchId, input: $input) {\n      matchDate\n      matchTime\n      division\n      matchType\n      matchLocation\n      homeTeam {\n        id\n      }\n      awayTeam {\n        id\n      }\n      onDuty {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMatch($updateMatchId: Int!, $input: UpdateMatchesInput!) {\n    updateMatch(id: $updateMatchId, input: $input) {\n      matchDate\n      matchTime\n      division\n      matchType\n      matchLocation\n      homeTeam {\n        id\n      }\n      awayTeam {\n        id\n      }\n      onDuty {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteMatch($deleteMatchId: Int!) {\n    deleteMatch(id: $deleteMatchId)\n  }\n"): (typeof documents)["\n  mutation DeleteMatch($deleteMatchId: Int!) {\n    deleteMatch(id: $deleteMatchId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteMatchResult($deleteMatchResultId: Int!) {\n    deleteMatchResult(id: $deleteMatchResultId)\n  }\n"): (typeof documents)["\n  mutation DeleteMatchResult($deleteMatchResultId: Int!) {\n    deleteMatchResult(id: $deleteMatchResultId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateAddress($input: UpdateAddressInput!, $memberId: Int!) {\n    updateAddress(input: $input, memberId: $memberId) {\n      id\n      street\n      houseNumber\n      suburb\n      postalCode\n      city\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateAddress($input: UpdateAddressInput!, $memberId: Int!) {\n    updateAddress(input: $input, memberId: $memberId) {\n      id\n      street\n      houseNumber\n      suburb\n      postalCode\n      city\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MemberAddress($memberId: Int!) {\n    memberAddress(memberID: $memberId) {\n      id\n      street\n      houseNumber\n      suburb\n      postalCode\n      city\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query MemberAddress($memberId: Int!) {\n    memberAddress(memberID: $memberId) {\n      id\n      street\n      houseNumber\n      suburb\n      postalCode\n      city\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MemberAttendance($memberId: Int!) {\n    memberAttendance(memberID: $memberId) {\n      id\n      createdAt\n      updatedAt\n      event {\n        id\n        name\n        startTime\n        endTime\n        eventType {\n          eventType\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query MemberAttendance($memberId: Int!) {\n    memberAttendance(memberID: $memberId) {\n      id\n      createdAt\n      updatedAt\n      event {\n        id\n        name\n        startTime\n        endTime\n        eventType {\n          eventType\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateMember(\n    $input: UpdateMemberInput!\n    $memberId: Int\n    $file: Upload\n  ) {\n    updateMember(input: $input, memberId: $memberId, file: $file) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      dob\n      gender\n      occupation\n      canBeReferredToBhv\n      status\n      joinDate\n      createdAt\n      updatedAt\n      notes\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMember(\n    $input: UpdateMemberInput!\n    $memberId: Int\n    $file: Upload\n  ) {\n    updateMember(input: $input, memberId: $memberId, file: $file) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      dob\n      gender\n      occupation\n      canBeReferredToBhv\n      status\n      joinDate\n      createdAt\n      updatedAt\n      notes\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MemberWithImage($memberId: Int) {\n    member(memberID: $memberId) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      dob\n      gender\n      occupation\n      canBeReferredToBhv\n      status\n      joinDate\n      createdAt\n      updatedAt\n      notes\n      image\n      membership {\n        id\n        memberType\n        price\n        description\n        maxSessions\n        maxGames\n        maxTrainings\n        ageRequirement\n        hasInjuryInsuranceCoverage\n        validMonths\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query MemberWithImage($memberId: Int) {\n    member(memberID: $memberId) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      dob\n      gender\n      occupation\n      canBeReferredToBhv\n      status\n      joinDate\n      createdAt\n      updatedAt\n      notes\n      image\n      membership {\n        id\n        memberType\n        price\n        description\n        maxSessions\n        maxGames\n        maxTrainings\n        ageRequirement\n        hasInjuryInsuranceCoverage\n        validMonths\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Memberships {\n    memberships {\n      id\n      memberType\n      price\n      description\n      maxSessions\n      maxGames\n      maxTrainings\n      ageRequirement\n      hasInjuryInsuranceCoverage\n      validMonths\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query Memberships {\n    memberships {\n      id\n      memberType\n      price\n      description\n      maxSessions\n      maxGames\n      maxTrainings\n      ageRequirement\n      hasInjuryInsuranceCoverage\n      validMonths\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteMember($memberId: Int) {\n    deleteMember(memberId: $memberId)\n  }\n"): (typeof documents)["\n  mutation DeleteMember($memberId: Int) {\n    deleteMember(memberId: $memberId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateMemberWithImage($input: CreateMemberInput!, $file: Upload) {\n    createMember(input: $input, file: $file) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      dob\n      gender\n      occupation\n      canBeReferredToBhv\n      status\n      joinDate\n      createdAt\n      updatedAt\n      notes\n      address {\n        id\n        houseNumber\n        suburb\n        postalCode\n        city\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMemberWithImage($input: CreateMemberInput!, $file: Upload) {\n    createMember(input: $input, file: $file) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      dob\n      gender\n      occupation\n      canBeReferredToBhv\n      status\n      joinDate\n      createdAt\n      updatedAt\n      notes\n      address {\n        id\n        houseNumber\n        suburb\n        postalCode\n        city\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Members {\n    members {\n      id\n      firstName\n      lastName\n      email\n      phone\n    }\n  }\n"): (typeof documents)["\n  query Members {\n    members {\n      id\n      firstName\n      lastName\n      email\n      phone\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MemberTransactions($memberId: Int!) {\n    memberTransactions(memberID: $memberId) {\n      amount\n      createdAt\n      date\n      id\n      notes\n      status\n      type\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query MemberTransactions($memberId: Int!) {\n    memberTransactions(memberID: $memberId) {\n      amount\n      createdAt\n      date\n      id\n      notes\n      status\n      type\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateMembership($input: CreateMembershipInput!) {\n    createMembership(input: $input) {\n      id\n      memberType\n      price\n      description\n      maxSessions\n      maxGames\n      maxTrainings\n      ageRequirement\n      hasInjuryInsuranceCoverage\n      validMonths\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMembership($input: CreateMembershipInput!) {\n    createMembership(input: $input) {\n      id\n      memberType\n      price\n      description\n      maxSessions\n      maxGames\n      maxTrainings\n      ageRequirement\n      hasInjuryInsuranceCoverage\n      validMonths\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Membership($membershipId: Int!) {\n    membership(id: $membershipId) {\n      id\n      memberType\n      price\n      description\n      maxSessions\n      maxGames\n      maxTrainings\n      ageRequirement\n      hasInjuryInsuranceCoverage\n      validMonths\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query Membership($membershipId: Int!) {\n    membership(id: $membershipId) {\n      id\n      memberType\n      price\n      description\n      maxSessions\n      maxGames\n      maxTrainings\n      ageRequirement\n      hasInjuryInsuranceCoverage\n      validMonths\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateMembership(\n    $input: UpdateMembershipInput!\n    $updateMembershipId: Int\n  ) {\n    updateMembership(input: $input, id: $updateMembershipId) {\n      id\n      memberType\n      price\n      description\n      maxSessions\n      maxGames\n      maxTrainings\n      ageRequirement\n      hasInjuryInsuranceCoverage\n      validMonths\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMembership(\n    $input: UpdateMembershipInput!\n    $updateMembershipId: Int\n  ) {\n    updateMembership(input: $input, id: $updateMembershipId) {\n      id\n      memberType\n      price\n      description\n      maxSessions\n      maxGames\n      maxTrainings\n      ageRequirement\n      hasInjuryInsuranceCoverage\n      validMonths\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteMembership($deleteMembershipId: Int!) {\n    deleteMembership(id: $deleteMembershipId)\n  }\n"): (typeof documents)["\n  mutation DeleteMembership($deleteMembershipId: Int!) {\n    deleteMembership(id: $deleteMembershipId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateMemberWithImageModal(\n    $input: CreateMemberInput!\n    $file: Upload\n  ) {\n    createMember(input: $input, file: $file) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      dob\n      gender\n      occupation\n      canBeReferredToBhv\n      status\n      joinDate\n      createdAt\n      updatedAt\n      notes\n      address {\n        id\n        houseNumber\n        suburb\n        postalCode\n        city\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMemberWithImageModal(\n    $input: CreateMemberInput!\n    $file: Upload\n  ) {\n    createMember(input: $input, file: $file) {\n      id\n      firstName\n      lastName\n      email\n      phone\n      dob\n      gender\n      occupation\n      canBeReferredToBhv\n      status\n      joinDate\n      createdAt\n      updatedAt\n      notes\n      address {\n        id\n        houseNumber\n        suburb\n        postalCode\n        city\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateMatch($input: CreateMatchesInput!) {\n    createMatch(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMatch($input: CreateMatchesInput!) {\n    createMatch(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateTeamMember($input: CreateTeamMemberInput!) {\n    createTeamMember(input: $input) {\n      id\n      role\n      position\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTeamMember($input: CreateTeamMemberInput!) {\n    createTeamMember(input: $input) {\n      id\n      role\n      position\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateTeam($input: CreateTeamInput!, $file: Upload) {\n    createTeam(input: $input, file: $file) {\n      id\n      teamName\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTeam($input: CreateTeamInput!, $file: Upload) {\n    createTeam(input: $input, file: $file) {\n      id\n      teamName\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Transaction($transactionId: Int!) {\n    transaction(id: $transactionId) {\n      id\n      type\n      status\n      amount\n      notes\n      date\n      createdAt\n      updatedAt\n      member {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n    }\n  }\n"): (typeof documents)["\n  query Transaction($transactionId: Int!) {\n    transaction(id: $transactionId) {\n      id\n      type\n      status\n      amount\n      notes\n      date\n      createdAt\n      updatedAt\n      member {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteTransaction($deleteTransactionId: Int!) {\n    deleteTransaction(id: $deleteTransactionId)\n  }\n"): (typeof documents)["\n  mutation DeleteTransaction($deleteTransactionId: Int!) {\n    deleteTransaction(id: $deleteTransactionId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateUser($input: CreateUsersInput!) {\n    createUser(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateUser($input: CreateUsersInput!) {\n    createUser(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query User($userId: Int!) {\n    user(id: $userId) {\n      id\n      name\n      username\n      email\n      role\n      status\n      lastLogin\n      createdAt\n      updatedAt\n      member {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n    }\n  }\n"): (typeof documents)["\n  query User($userId: Int!) {\n    user(id: $userId) {\n      id\n      name\n      username\n      email\n      role\n      status\n      lastLogin\n      createdAt\n      updatedAt\n      member {\n        id\n        firstName\n        lastName\n        email\n        phone\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateUser($input: UpdateUsersInput!, $updateUserId: Int) {\n    updateUser(input: $input, id: $updateUserId) {\n      id\n      name\n      username\n      email\n      role\n      status\n      lastLogin\n      createdAt\n      updatedAt\n      member {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUser($input: UpdateUsersInput!, $updateUserId: Int) {\n    updateUser(input: $input, id: $updateUserId) {\n      id\n      name\n      username\n      email\n      role\n      status\n      lastLogin\n      createdAt\n      updatedAt\n      member {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateUserPassword(\n    $input: UpdateUsersInput!\n    $updateUserId: Int\n    $oldPassword: String\n  ) {\n    updateUser(input: $input, id: $updateUserId, oldPassword: $oldPassword) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserPassword(\n    $input: UpdateUsersInput!\n    $updateUserId: Int\n    $oldPassword: String\n  ) {\n    updateUser(input: $input, id: $updateUserId, oldPassword: $oldPassword) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Login($input: LoginInput!) {\n    login(input: $input) {\n      id\n      name\n      role\n      status\n      token\n    }\n  }\n"): (typeof documents)["\n  mutation Login($input: LoginInput!) {\n    login(input: $input) {\n      id\n      name\n      role\n      status\n      token\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateUserPublicSignUp($input: CreateUsersInput!) {\n    createUser(input: $input) {\n      id\n      name\n      username\n      email\n      member {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateUserPublicSignUp($input: CreateUsersInput!) {\n    createUser(input: $input) {\n      id\n      name\n      username\n      email\n      member {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query Events {\n      events {\n        id\n        name\n        description\n        notes\n        status\n        startTime\n        endTime\n        location\n        createdAt\n        updatedAt\n        eventType {\n          eventType\n        }\n      }\n    }\n  "): (typeof documents)["\n    query Events {\n      events {\n        id\n        name\n        description\n        notes\n        status\n        startTime\n        endTime\n        location\n        createdAt\n        updatedAt\n        eventType {\n          eventType\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Query {\n    matchesSlice {\n      id\n      matchDate\n      matchTime\n      division\n      matchType\n      matchLocation\n      createdAt\n      updatedAt\n      homeTeam {\n        teamName\n      }\n      awayTeam {\n        teamName\n      }\n      onDuty {\n        teamName\n      }\n    }\n  }\n"): (typeof documents)["\n  query Query {\n    matchesSlice {\n      id\n      matchDate\n      matchTime\n      division\n      matchType\n      matchLocation\n      createdAt\n      updatedAt\n      homeTeam {\n        teamName\n      }\n      awayTeam {\n        teamName\n      }\n      onDuty {\n        teamName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MatchScores($matchId: Int!) {\n    matchScores(matchId: $matchId) {\n      id\n      set\n      points\n      matchDate\n      outcome\n      team {\n        teamName\n      }\n    }\n  }\n"): (typeof documents)["\n  query MatchScores($matchId: Int!) {\n    matchScores(matchId: $matchId) {\n      id\n      set\n      points\n      matchDate\n      outcome\n      team {\n        teamName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteScore($deleteScoreId: Int!) {\n    deleteScore(id: $deleteScoreId)\n  }\n"): (typeof documents)["\n  mutation DeleteScore($deleteScoreId: Int!) {\n    deleteScore(id: $deleteScoreId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TeamMembers {\n    teamMembers {\n      id\n      role\n      position\n      member {\n        firstName\n        lastName\n      }\n      team {\n        teamName\n      }\n    }\n  }\n"): (typeof documents)["\n  query TeamMembers {\n    teamMembers {\n      id\n      role\n      position\n      member {\n        firstName\n        lastName\n      }\n      team {\n        teamName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query EventTypes {\n      eventTypes {\n        id\n        eventType\n        description\n        ageRequirement\n        createdAt\n        updatedAt\n      }\n    }\n  "): (typeof documents)["\n    query EventTypes {\n      eventTypes {\n        id\n        eventType\n        description\n        ageRequirement\n        createdAt\n        updatedAt\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TableMembers {\n    members {\n      id\n      firstName\n      lastName\n      email\n      phone\n      membership {\n        memberType\n      }\n    }\n  }\n"): (typeof documents)["\n  query TableMembers {\n    members {\n      id\n      firstName\n      lastName\n      email\n      phone\n      membership {\n        memberType\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query Memberships {\n      memberships {\n        id\n        memberType\n        price\n        description\n        maxSessions\n        maxGames\n        maxTrainings\n        ageRequirement\n        hasInjuryInsuranceCoverage\n        validMonths\n        createdAt\n        updatedAt\n      }\n    }\n  "): (typeof documents)["\n    query Memberships {\n      memberships {\n        id\n        memberType\n        price\n        description\n        maxSessions\n        maxGames\n        maxTrainings\n        ageRequirement\n        hasInjuryInsuranceCoverage\n        validMonths\n        createdAt\n        updatedAt\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query UsersSlice {\n      usersSlice {\n        id\n        name\n        username\n        email\n        role\n        status\n        lastLogin\n        createdAt\n        updatedAt\n      }\n    }\n  "): (typeof documents)["\n    query UsersSlice {\n      usersSlice {\n        id\n        name\n        username\n        email\n        role\n        status\n        lastLogin\n        createdAt\n        updatedAt\n      }\n    }\n  "];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;