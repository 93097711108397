import { Outlet } from "react-router-dom";

import Login from "@/pages/Login";
interface ProtectedRouteProps {
  isAuthenticated: boolean;
  allowedRoles?: string[];
  userRole?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAuthenticated,
  allowedRoles,
  userRole,
}) => {
  if (
    isAuthenticated &&
    (!allowedRoles || allowedRoles.includes(userRole || ""))
  ) {
    return <Outlet />;
  } else {
    return <Login />;
  }
};

export default ProtectedRoute;
